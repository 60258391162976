import React from "react"
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import update from "immutability-helper";

class TenantList extends React.Component {
  static propTypes = {
    dataSourceUrl: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    search: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    property: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      tenants: this.props.tenants || [],
      selectedTenants: [],
      allSelected: false,
      selection: [],   // init state has empty selection
      inputValue: ''
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) {
      // The tenant list was activated
      if (this.props.property && this.props.property.tenants) {
        // A property was set - show the properties tenants
        this.setState({
          tenants: this.props.property.tenants
        })
      } else {
        if(this.props.value !== ''){
          this.loadData(this.props.dataSourceUrl, {'q': this.props.value});
        }else{
          // No property set - load all tenants
          this.loadData(this.props.dataSourceUrl);
        }
      }
    }
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({ loading: true });

    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then(function (response) {
          this.setState({
            tenants: response.data.tenants || [],
          });
        }.bind(this))
        .catch(function (error) {
          console.log("Error loading data tenants: ", error);
        })
        .finally(function () {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }.bind(this));

    return promise;
  }

  search = (e) => {
    e.preventDefault();
    this.loadData(this.props.dataSourceUrl, {'q': this.state.inputValue});
  }
  selectable = (data, query = {}) => {
    return (Object.keys(query).length == 0 ? true : data.has_consent == query.consent)
  }

  allSelectableItems = (query = {}) => {
    return this.state.tenants.filter(x => this.selectable(x, query));
  }

  isSelected = (item) => {
    return (this.state.selectedTenants.includes(item));
  }

  rowClassName = (item) => {
    return { 'ui-state-highlight': this.isSelected(item) };
  }

  onRowClick = (e) => {
    const item = e.data;

    if (this.selectable(item)) {
      this.toggleSelection(item);
    }
  }

  toggleSelection = (item) => {
    let selection;

    if (this.isSelected(item)) {
      selection = this.state.selectedTenants.filter(x => x !== item);
    } else {
      selection = [...this.state.selectedTenants, item];
    }
    const allSelected = selection.length === this.allSelectableItems().length;
    this.setState({ allSelected, selectedTenants: selection });
  }

  toggleAll = (allSelected) => {
    const selection = allSelected ? this.allSelectableItems() : [];
    this.setState({ allSelected, selectedTenants: selection });
  }

  toggleAllWithConsent = (allSelected) => {
    const selection = allSelected ? this.allSelectableItems({ consent: true }) : [];
    this.setState({ selectedTenants: selection });
  }

  toggleAllWithoutConsent = (allSelected) => {
    const selection = allSelected ? this.allSelectableItems({ consent: false }) : [];
    this.setState({ selectedTenants: selection });
  }

  pushTenantSelection = () => {
    var selectedTenants = this.state.selectedTenants;
    var $addNewLink = $("#recipients-add-new");

    selectedTenants.forEach((tenant) => {
      if (this.recipientExist(tenant.email) == false) {
        var $newRecipient = this.getCocoonRecipientsNotDeleted().last();

        if ($newRecipient.find('.recipient-email').val() != '') {
          $addNewLink.trigger("click");
          $newRecipient = this.getCocoonRecipientsNotDeleted().last();
        }

        var recipientEmailField = $newRecipient.find('.recipient-email');
        var recipientFullNameField = $newRecipient.find('.recipient-fullname');
        var recipientFirstNameField = $newRecipient.find('.recipient-firstname');
        var recipientLastNameField = $newRecipient.find('.recipient-lastname');
        var recipientLocaleField = $newRecipient.find('.recipient-locale');
        var recipientTenantIdField = $newRecipient.find('.recipient-tenant-id');

        recipientEmailField.val(tenant.email);
        recipientFullNameField.val(tenant.full_name);
        recipientFirstNameField.val(tenant.first_name);
        recipientLastNameField.val(tenant.last_name);
        recipientLocaleField.val(tenant.locale);
        recipientTenantIdField.val(tenant.id);

        var $reciplocale = $newRecipient.find('.recipient-item-locale');

        $reciplocale.find('option').each(function () {
          if ($(this).val() == tenant.locale) {
            $(this).prop('selected', true);
          }
        });

        $newRecipient.find(".recipient-item-email").html(tenant.email);
        $newRecipient.find(".recipient-item-fullname").html(tenant.full_name);

        var consentIcon = tenant.has_consent ? 'fa-check text-green' : 'fa-close text-red';
        $newRecipient.find('.recipient-item-consent-received').html('<i class="fa ' + consentIcon + '"></i>');
      }
    });

    this.props.onClose();
  }

  updateInput = (e) => {
    this.setState({
      inputValue: e.target.value
    });
  }

  getCocoonRecipientsNotDeleted = () => {
    return $("#recipients .nested-fields").filter(function (i, el) {
      return ($(el).find(".remove-link input").val() == "false")
    })
  };

  recipientExist = (recipientEmail) => {
    return (this.getCocoonRecipientsNotDeleted().find(".recipient-email").filter(function () { return this.value == recipientEmail }).length > 0);
  }

  render() {
    var selectedTenantsLength = this.state.selectedTenants.length;
    var totalTenantsLength = this.state.tenants.length;

    return (
      <div className={classNames({ "hidden": !this.props.show })}>

        <div className="row m-b-20">
          <div className="col-xs-6">
            <a href="javascript:void(0);" onClick={this.props.onHide} className="font-size-14"><i className="fa fa-chevron-left spacer-right-xxs" aria-hidden="true"></i>
              {I18n.t("properties.back_to_properties")}
            </a>
          </div>
          <div className="col-xs-6 text-right">
            <span className="text-regular font-size-16">({I18n.t("general.recipients_selected_count", { count: selectedTenantsLength, total: totalTenantsLength })})</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <div className="btn-group">
              <div className="search-input-group">
                <div className="input-group">
                  <input type="text" name="q" id="q" onChange={e => this.updateInput(e)} placeholder="Recherche" className="form-control form-control-search expanded modal"/>
                  <span className="input-group-addon">
                    <div className="btn btn-option btn-search-option btn-modal" onClick={this.search}><a href="javascript:void(0);" className="font-size-14 text-light"><i className="fa fa-search"></i></a></div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames("row m-b-15", { "hidden": totalTenantsLength == 0 })}>
          <div className="col-xs-12">
            <h5 className="font-size-14">
              <span className="spacer-right-xxs text-regular">{I18n.t("properties.tenants.select_filter")}</span>
              <a href="javascript:void(0);" className="text-light" onClick={e => this.toggleAll(!this.state.allSelected)}>{I18n.t("properties.tenants.filters.all")}</a>
              <span className="text-gray">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
              <a href="javascript:void(0);" className="text-light" onClick={this.toggleAllWithConsent}>{I18n.t("properties.tenants.filters.with_consent")}</a>
              <span className="text-gray">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
              <a href="javascript:void(0);" className="text-light" onClick={this.toggleAllWithoutConsent}>{I18n.t("properties.tenants.filters.without_consent")}</a>
            </h5>
          </div>
        </div>

        <DataTable
          value={this.state.tenants}
          onRowClick={this.onRowClick}
          rowClassName={this.rowClassName}
          autoLayout={true}
          style={{ display: "block" }}
        >
          <Column
            sortable={false}
            header={
              <Checkbox
                onChange={e => this.toggleAll(e.checked)}
                checked={this.state.allSelected}
              />
            }
            body={(rowData) => (
              <Checkbox onChange={() => { }} checked={this.isSelected(rowData)} />
            )}
          />

          <Column field="full_name" header={I18n.t("form.full_name")} sortable={false} />
          <Column field="email" header={I18n.t("form.email")} sortable={false} />
          <Column field="unit" header={I18n.t("form.unit")} sortable={false} />

          <Column
            sortable={false}
            header={I18n.t("general.consent_status")}

            body={(rowData) => (
              rowData.has_consent ? <i className='fa fa-check text-green'></i> : <i className='fa fa-times text-red'></i>
            )}
          />

        </DataTable>

        {/* spinner */}
        <div className={classNames("form-overlay text-center", { "hidden": !this.state.loading })}>
          <BeatLoader
            css={{ display: 'block', margin: '0 auto' }}
            sizeUnit={"px"}
            size={15}
            color={'#0056ba'}
            loading={this.state.loading}
          />
        </div>

        <div className="row m-t-20">
          <div className="col-xs-6 text-left">
            <button type="button" className="btn btn-gray btn-custom-margin" data-dismiss="modal" onClick={this.props.onClose}>{I18n.t("general.cancel")}</button>
          </div>
          <div className="col-xs-6 text-right">
            <button type="button" className="btn btn-main-pronotif btn-custom-margin" onClick={this.pushTenantSelection} disabled={selectedTenantsLength === 0}>{I18n.t("general.add_selection")}</button>
          </div>
        </div>

      </div>
    );
  }
}

export default TenantList;
