import React     from 'react'
import PropTypes from 'prop-types'
import {
  FullName,
  Container,
  CampaignName,
  AmountDue,
  AccountNumber,
}                from './SmsDetails.styles'

export const SmsDetails = ({fullName, campaignName, amountDue, accountNumber, locale}) =>
  <Container>
    <FullName>Conversation with <span>{fullName}</span></FullName>
    <CampaignName>From the
      campaign <span>"{campaignName}"</span> ({locale.toUpperCase()})
    </CampaignName>
    <AmountDue>Amount due: <span>{amountDue}</span></AmountDue>
    <AccountNumber>Account number: <span>{accountNumber}</span></AccountNumber>
  </Container>

SmsDetails.propTypes = {
  campaign: PropTypes.object,
  debtor:   PropTypes.object,
}

export default SmsDetails