$(document).on("turbolinks:load", function () {
  $(document).on('click','.doc-collect-item-upload-link', function(e) {
    var container = $(this).closest(".doc-collect-item").find(".activity-uploader");
    var reactComponent = container.data("react-component");
    if (reactComponent != null){
      reactComponent.openFileSelection();
    }
    
  });
});
