import React from "react"

class RecipientListPickerRow extends React.Component {
  render() {
    return (
      <tr onClick={this.props.onClick}>
        <td>{this.props.recipientList.name}</td>
        <td>{this.props.recipientList.recipients_count}</td>
      </tr>
    );
  }
}

class RecipientListPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipientLists: []
    };
  }

  componentDidMount() {
    this.serverRequest = $.get(this.props.source, ((data) => {
      this.setState({
        recipientLists: data.recipient_lists
      });
    }).bind(this));
  }

  componentWillUnmount() {
    this.serverRequest.abort();
  }

  open() {
    var $node = $(this.modalNode)
    $node.modal("show")
  }

  close() {
    var $node = $(this.modalNode)
    $node.modal("hide")
  }

  selectRecipientList(recipientList) {
    var $addNewLink = $("#recipients-add-new");

    recipientList.recipients.forEach((recipient) => {
      var $newRecipient = $("#recipients .nested-fields:last");
      if ($newRecipient.find(".recipient-email").val() != '') {
        $addNewLink.trigger("click");
        $newRecipient = $("#recipients .nested-fields:last");
      }
      $newRecipient.find('.recipient-email').val(recipient.email);
      $newRecipient.find('.recipient-fullname').val(recipient.full_name);
      $newRecipient.find('.recipient-firstname').val(recipient.first_name);
      $newRecipient.find('.recipient-lastname').val(recipient.last_name);
      $newRecipient.find('.recipient-description').val(recipient.description);
      $newRecipient.find('.recipient-locale').val(recipient.locale);
      updateRecipientConsentStatus($newRecipient);
    });

    var totalPages = Math.ceil($("#recipients .nested-fields").length / window.recipientsPaginationItemsPerPage);
    var $recipientsPagination = $(".recipients-pagination");

    $recipientsPagination.twbsPagination("destroy");
    $recipientsPagination.twbsPagination($.extend({}, window.recipientsPaginationOptions, {
      startPage: 1,
      totalPages: totalPages,
      onPageClick(e, page) {
        var index = page - 1;
        var startFrom = index * window.recipientsPaginationItemsPerPage;
        var endOn = startFrom + window.recipientsPaginationItemsPerPage;

        $("#recipients .nested-fields").hide().slice(startFrom, endOn).show();
      }
    }));

    this.close();
  }

  render() {

    var rows = this.state.recipientLists.map((recipientList) => {
      return (
        <RecipientListPickerRow
          recipientList={recipientList}
          key={recipientList.id}
          onClick={this.selectRecipientList.bind(this, recipientList)}
          />
      );
    });

    return (
      <div className="modal fade" tabIndex="-1" ref={(ref) => this.modalNode = ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
              <h4 className="modal-title">{I18n.t("general.recipient_lists")}</h4>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-hover table-select">
                  <thead>
                    <tr>
                      <th>{I18n.t("form.name")}</th>
                      <th style={{ width: "30%" }}>{I18n.t("general.recipients")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecipientListPicker;
