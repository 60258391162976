var dragging = false;
var stopDraggingTimeout = null;

$(document).on("dragenter", "body", function(e) {
  dragging = true;
  clearTimeout(stopDraggingTimeout);
  var body = $("body");
  body.addClass("dragging");
});

$(document).on("dragleave", "body", function(e) {
  dragging = false;
  stopDraggingTimeout = setTimeout(function() {
    if (!dragging) {
      var body = $("body");
      body.removeClass("dragging");
    }
  }, 1500);
});

$(document).on("dragover", "body", function(e) {
  e.preventDefault();
});

$(document).on("drop", "body", function(e) {
  e.preventDefault();
  dragging = false;
  clearTimeout(stopDraggingTimeout);
  var body = $("body");
  body.removeClass("dragging");
});

$(document).on("drop", ".documents-drop-zone", function(e) {
  if (!e.originalEvent.dataTransfer || !e.originalEvent.dataTransfer.files) return;

  handleFiles(e.originalEvent.dataTransfer.files, e.currentTarget);
});


$(document).on("click", ".documents-add-new", function(e) {
  if (e.originalEvent !== undefined) { // Makes sure it's triggered from a click, not jQuery handleFiles() method
    var addLink = $(e.currentTarget);
    if (addLink.attr("data-association-insertion-node") != undefined){
      var container = $(addLink.attr("data-association-insertion-node"));
      container.find("input[type=file]:first").trigger("click");
    }
  }
});

function handleFiles(files, currentTarget) {
  var input = null;
  var container = null;
  var addLink = $(currentTarget).find(".documents-add-new:first");
  if (addLink.length > 0) {
    if (addLink.attr("data-association-insertion-node") != undefined){
      container = $(addLink.attr("data-association-insertion-node"));
    }
    
    if (container) {
      addLink.trigger("click");
      input = container.find("input[type=file]:first").get(0);
    }

    if (input && isValid(container, input, files)) {
      input.files = files;
      $(".documents").show();
    }
  }
  else {
    var reader = new FileReader();
    const fileInput = $(currentTarget).find('.btn-file > input[type="file"]');
    
    if (files.length > 0 && isValid($(currentTarget).parents('.documents-upload-container'), fileInput, files)) {
      reader.onload = function(e) {
        $("#organization-logo-upload").attr('src', e.target.result);
        fileInput.prop('files', files);
        $(".upload-logo-empty").addClass("hidden");
        $(".upload-logo-filled").removeClass("hidden");
      }

      reader.readAsDataURL(files[0]);
    }
  }
}

function isValidFileTypes(input, files) {
  const typesString = $(input).attr('accept');
  if (typesString) {
    types = typesString.split(',');
    return Array.from(files).every(function (file) {
      return types.includes(file.type)
    })
  }

  return true;
}

function isValid(container, input, files) {
  var valid = true;
  var message;
  const errorLabel = container.find('.error-message');

  if (!isValidFileTypes(input, files)) {
    valid = false;
    message = I18n.t('general.errors.invalid_file_format');
  }

  errorLabel.toggle(!valid);
  if (errorLabel) {
    errorLabel.text(message);
  }

  return valid;
}

$(document).on("change", ".btn-file > input[type=file]", function () {
  var input = this;

  var reader = new FileReader();
  if (input.files && input.files.length > 0 && input.files[0]) {

    reader.onload = function(e) {
      $("#organization-logo-upload").attr('src', e.target.result);
      $(".upload-logo-empty").addClass("hidden");
      $(".upload-logo-filled").removeClass("hidden");
    }

    reader.readAsDataURL(input.files[0]);
  }
});

$(document).on("click", ".remove-organization-logo-btn", function() {
  $('.btn-file > input[type="file"]').val('');
  $("#organization-logo-upload").attr('src', '');
  $(".upload-logo-empty").removeClass("hidden");
  $(".upload-logo-filled").addClass("hidden");
});

const observer = new MutationObserver(function (mutations) {
  mutations.forEach(function (mutation) {
    if (mutation.addedNodes && mutation.addedNodes.length > 0) {
      // element added to DOM
      const hasClass = [].some.call(mutation.addedNodes, function (el) {
        return el.classList?.contains('swal2-container')
      });
      if (hasClass) {
        $('#property_logo').attr("disabled", "disabled");
      }
    } else if (mutation.removedNodes && mutation.removedNodes.length > 0) {
      // element removed from DOM
      const hasClass = [].some.call(mutation.removedNodes, function (el) {
        return el.classList?.contains('swal2-container')
      });
      if (hasClass) {
        $('#property_logo').removeAttr("disabled")
      }
    }
  });
});

const config = {
  attributes: true,
  childList: true,
  characterData: true
};

$( document ).ready(function() {
  observer.observe(document.body, config);
});
