import React, { Component } from 'react';
import classNames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';

import DocCollectItemsList from './DocCollectItemsList';
import RepliesList from './RepliesList';
export class ActivityTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabKey: 1,
      expanded: false,
      tabs: this.initTabs(this.props.activity)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expanded !== this.props.expanded) {
      this.setState({
        expanded: this.props.expanded
      })
    }
  }

  renderMessagesTab = (tab) => {
    const activity = this.props.activity
    const recipient = activity.recipients[0]

    let visible = (this.state.activeTabKey == tab.key && this.state.expanded) ? true : false

    return (
      <div className="row">
        {
          activity.recipients[0].sent_email_url != null && activity.emails_sent_at != null ?
            (
              // change icon back to fa-external link when message can be displayed in modal
              <div className="col-xs-12 spacer-top-xxs spacer-bottom-xs text-center">
                <a href={activity.recipients[0].sent_email_url} target="_blank" className="navy-link link-underline font-size-14">
                  <i className="fal fa-file-pdf m-r-5"></i>{I18n.t("people.sent_email_modal.open_cta")}
                </a>
              </div>
            )
            :
            ""
        }
        <div className="col-xs-12">
          <RepliesList
            dataSourceUrl={recipient.replies_path}
            replySubject={activity.subject}
            personFullName={activity.person.full_name}
            visible={visible}
            showSMSReply={recipient.show_sms_reply}
          />
        </div>
      </div>
    )
  }

  handleSelect = (tabKey) => {
    this.setState({ activeTabKey: tabKey });
  }

  initTabs = (activity) => {
    let tabs = [{ key: 1, title: I18n.t("people.messages_title"), content: "messages" }];

    if (activity.type == "DocCollectRequest") {
      tabs.push({ key: 3, title: I18n.t("people.documents"), content: "documents" });
    }

    return tabs;
  }

  renderTab = (tab) => {
    let visible = (this.state.activeTabKey == tab.key) ? true : false;

    if (tab.content == "messages") {
      return this.renderMessagesTab(tab);
    } else if (tab.content == "documents") {
      return (
        <DocCollectItemsList dataSourceUrl={this.props.activity.recipients[0].doc_collect_items_path} visible={visible} />
      )
    }
  }

  render() {
    let tabs = this.state.tabs.map((tab, i) => {
      return (
        <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
          {this.renderTab(tab)}
        </Tab>
      )
    });

    return (
      <Tabs id="person-activity-list-item-tabs"
        activeKey={this.state.activeTabKey}
        defaultActiveKey={1}
        onSelect={this.handleSelect}
        animation={false}
        className={classNames('spacer-top-xs', { 'hidden': !this.state.expanded })}>
        {tabs}
      </Tabs>
    )
  }
}

export default ActivityTabs;
