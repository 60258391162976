import React from 'react'
import ReactDOM from 'react-dom'

// https://github.com/FineUploader/fine-uploader
// https://github.com/FineUploader/react-fine-uploader
// https://github.com/FineUploader/fine-uploader-wrappers#azure
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import FineUploaderAzure from 'fine-uploader-wrappers/azure'
import { sum, map as lodashMap } from 'lodash'
import ClioDocImporter from '../clio_doc_importer/clio_doc_importer'
import ActivityUploaderFile from './activity_uploader_file'
import STATUS from '../../constants/file_upload_statuses';

// const SINGLE_SIZE_LIMIT = 2048000000; // 2048000000 = 2Gb
// const TOTAL_SIZE_LIMIT = 1024000000; // 1024000000 = 1Gb
class ActivityUploader extends React.Component {

  constructor(props) {
    super(props);
    const uploader = new FineUploaderAzure({
      options: {
        request: {
          endpoint: ''
        },
        signature: {
          endpoint: (props.activity_id != null) ? `/uploads/signature/${  props.activity_id}` : '/uploads/signature'
        },
        uploadSuccess: {
          endpoint: (props.activity_id != null) ? `/uploads/done/${  props.activity_id}` : '/uploads/done',
        },
        deleteFile: {
          enabled: true
        },
        chunking: {
          enabled: true,
          partSize: 4000000, // Don't change, Azure doesn't support higher number
          concurrent: {
            enabled: true
          },
        },
        retry: {
          enableAuto: true
        },
        callbacks: {
          onValidate: function (file) {
            const fileTypeValid = this.validateFileType(file.name);
            let singleFileSizeValid = true;
            let totalFileSizeValid = true;
            this.setState({ calculated_total_size: this.state.calculated_total_size + file.size });

            if (this.state.total_size) { totalFileSizeValid = this.validateTotalSize(file.size); }
            if (this.state.single_size) { singleFileSizeValid = this.validateSingleSize(file.size); }

            return fileTypeValid && singleFileSizeValid && totalFileSizeValid;
          }.bind(this)
        }
      }
    });

    const files = [];
    if (!this.props.type) console.error('No `type` property found.');
    if (this.props.clio_doc_enabled && !this.props.activity_id) console.error('No `activity_id` property found.');

    const enable_drop_zone = props.enable_drop_zone != null ? props.enable_drop_zone : true;

    let doc_base_idx = 0;
    if (this.props.doc_base_idx != null && parseInt(this.props.doc_base_idx) > 0) {
      doc_base_idx = parseInt(this.props.doc_base_idx);
    }

    let allowed_extensions_parts = [];
    if (this.props.allowed_extensions != null && this.props.allowed_extensions.length > 0) {
      allowed_extensions_parts = this.props.allowed_extensions.split(',')
    }

    if (this.props.documents) {
      this.props.documents.forEach((document) => {
        files.push({
          id: document.id,
          document,
          status: document.processing_pending ? STATUS.PROCESSING : STATUS.UPLOADED
        })
      });
    }

    this.state = {
      files,
      uploader,
      enable_drop_zone,
      file_extension_error: '',
      doc_base_idx,
      allowed_extensions_parts,
      single_size_error: '',
      total_size_error: '',
      single_size: this.convertMegaBytesToBytes(this.props.single_size),
      total_size: this.convertMegaBytesToBytes(this.props.total_size),
      available_doc_size: this.calculateAvailableDocSize(),
      calculated_total_size: this.calculateTotalSize(files)
    }
  }

  componentDidMount() {
    const {uploader} = this.state;

    uploader.on('submitted', this.addFile.bind(this));
    uploader.on('statusChange', this.statusChange.bind(this));
    uploader.on('complete', this.uploadCompleted.bind(this));
    uploader.on('allComplete', this.allCompleted.bind(this));
    $(document).trigger('activities.form.uploader_mounted');
  }

  componentDidUpdate() {
    $(ReactDOM.findDOMNode(this))
      .trigger('react.activity_uploader.file_list_updated');
  }

  calculateAvailableDocSize() {
    if (this.props.total_size) {
      return this.convertMegaBytesToBytes(this.props.total_size) - (this.props.doc_size_count || 0)
    }
    return 0
  }

  convertMegaBytesToBytes(mb) {
    return mb * 1024 * 1024
  }

  importFromClio() {
    const oldModalContainer = document.getElementById('modal-holder')
    const newModalContainer = oldModalContainer.cloneNode(false);
    oldModalContainer.parentNode.replaceChild(newModalContainer, oldModalContainer);

    this._clioDocImporter = ReactDOM.render(
      <ClioDocImporter activity_id={this.props.activity_id} recipient_id={this.props.recipient_id} docs_uploaded={this.clioDocsUploaded.bind(this)} clio_matters_search_url={this.props.clio_matters_search_url} clio_docs_url={this.props.clio_docs_url} clio_add_doc_url={this.props.clio_add_doc_url} />,
      newModalContainer
    );

    this._clioDocImporter.open();
  }

  calculateTotalSize(files) {
    return sum(lodashMap(files, (file) => { return file.status === STATUS.REMOVED ? 0 : file.document.file_size }))
  }

  validateFileType(fileName) {
    this.setState({file_extension_error: ''})
    let isAllowed = false;
    if (this.state.allowed_extensions_parts.length == 0) {
      isAllowed = true;
    }

    let blackList = ['bat', 'exe', 'com', 'dll', 'vbs', 'js', 'reg', 'cgi', 'htaccess', 'sh', 'ade', 'adp', 'bat', 'chm', 'cmd', 'com', 'cpl', 'exe', 'hta', 'ins', 'isp', 'jar', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msp', 'mst', 'pif', 'scr', 'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh', 'heic']
    if (this.props.no_video) {
      blackList = blackList.concat(['.webm', '.mkv', '.flv', '.vob', '.ogv', '.ogg', '.drc', '.gif', '.gifv', '.mng', '.avi', '.mov', '.wmv', '.yuv', '.mpg', '.m4v', 'svi', '.3gp', 'mxf', '.roq', '.3g2', '.3gp', '.mp4'])
    }
    if (blackList.some((e) => fileName.toLowerCase().endsWith(e))) {
      this.setState({file_extension_error: fileName})
      isAllowed = false
    }

    this.state.allowed_extensions_parts.some((fileExt) => {
      const re = new RegExp(`\\${  fileExt.trim()}`, 'gi');
      const result = fileName.match(re);
      if (result != null) {
        isAllowed = true;
        this.setState({file_extension_error: ''})
      } else {
        this.setState({file_extension_error: fileName})
      }
      // this will exit some if result is initialized
      return isAllowed
    });
    return isAllowed;
  }

  validateTotalSize(newSize) {
    this.setState({ total_size_error: '' })
    
    let isValid = true;
    if (this.state.calculated_total_size <= this.state.available_doc_size) {
      isValid = true;
      this.setState({total_size_error: ''})
    } else {
      isValid = false;
      this.setState({total_size_error: I18n.t('general.uploader.total_size_error', {size: this.props.total_size}), calculated_total_size: this.state.calculated_total_size - newSize })
    }
    return isValid;
  }

  validateSingleSize(newSize) {
    this.setState({ single_size_error: '' })

    let isValid = true;
    if (this.state.calculated_total_size <= this.state.single_size) {
      isValid = true;
      this.setState({single_size_error: ''})
    } else {
      isValid = false;
      this.setState({single_size_error: I18n.t('general.uploader.single_size_error', {size: this.props.single_size}), calculated_total_size: this.state.calculated_total_size - newSize})
    }
    return isValid;
  }

  clioDocsUploaded(documents) {
    const {files} = this.state;

    documents.forEach((doc) => {
      files.push({
        id: doc.id,
        document: doc,
        status: doc.processing_pending ? STATUS.PROCESSING : STATUS.UPLOADED
      })
    })

    this.setState({ files })

    setTimeout(function () {
      this._clioDocImporter.close();
      setTimeout(function () {
        this._clioDocImporter.destroy();
        this._clioDocImporter = null;
      }.bind(this), 1000);
    }.bind(this), 2000);
  }

  addFile(id, name) {
    const {files} = this.state;
    const file = {
      id,
      name,
      status: STATUS.SUBMITTED,
      uploaderStatus: null
    }

    files.push(file);
    this.setState({ files });
    this.disableSubmit();
  }

  removeFile(id) {
    const files = this.state.files;
    const file = this.findFileById(id);
    const index = files.indexOf(file);
    if (!file || index == -1) return;

    if (file.status == STATUS.UPLOADING) {
      this.state.uploader.methods.cancel(id);
      files.splice(index, 1);
      this.uploadCancelled();
    } else {
      file.status = STATUS.REMOVED;
    }
    this.setState({ files: files, calculated_total_size: this.state.calculated_total_size - file.document.file_size });
  }

  findFileById(id) {
    return this.state.files.find(function (file) { return file.id == id });
  }

  openFileSelection() {
    $(ReactDOM.findDOMNode(this)).find('input[type=file]').trigger('click');
  }

  statusChange(id, oldStatus, newStatus) {
    const file = this.findFileById(id);
    const UPLOADER_STATUS = this.state.uploader.qq.status;
    if (!file) return;

    file.uploaderStatus = newStatus;

    switch (file.uploaderStatus) {
      case UPLOADER_STATUS.SUBMITTED:
        file.status = STATUS.SUBMITTED;
        break;

      case UPLOADER_STATUS.UPLOADING:
        file.status = STATUS.UPLOADING;
        break;

      case UPLOADER_STATUS.UPLOAD_SUCCESSFUL:
        file.status = STATUS.PROCESSING;
        break;
    }

    this.setState({ files: [...this.state.files] });
  }

  uploadCompleted(id, name, responseJSON, xhr) {

    const file = this.findFileById(id);
    if (!file) return;

    file.document = responseJSON.document;
    file.status = file.document.processing_pending ? STATUS.PROCESSING : STATUS.UPLOADED;

    this.setState({ files: [...this.state.files] });
    this.enableSubmit();
  }

  uploadCancelled() {
    this.enableSubmit();
  }

  allCompleted() {
  }

  findSubmitButtons() {
    return $(".js-activity-form button[type='submit']").add($('.activity-actionbar .nav-btn'));
  }

  disableSubmit() {
    const $buttons = this.findSubmitButtons();
    const first = $buttons.first();
    const count = first.data('disable-count') || 0;
    $buttons.attr('disabled', true).addClass('disabled').addClass('btn-loading');
    first.data('disable-count', count + 1);
  }

  enableSubmit() {
    const $buttons = this.findSubmitButtons();
    const first = $buttons.first();
    let count = first.data('disable-count');
    count -= 1;
    first.data('disable-count', count)
    if (count == 0) {
      $buttons.attr('disabled', false).removeClass('disabled').removeClass('btn-loading');
    }
  }

  render() {
    let recipientIdField = null;
    let dropzoneHtml = '';
    let clioImportLink = null;

    if (this.props.clio_doc_enabled) {
      clioImportLink = (
        <span>
          &nbsp;
          <span className='text-downcase text-gray'>{I18n.t('general.or')}</span>
          &nbsp;
          <a onClick={this.importFromClio.bind(this)}><span className='text-underline text-gray'>{I18n.t('activities.sections.documents.title_clio', { defaultValue: 'Import from Clio' })}</span></a>
        </span>
      )
    }

    if (this.props.recipient_id != null && this.props.type != 'recipient') {
      const fieldName = `${this.props.type}[recipients_attributes][${this.props.recipient_idx}][id]`;

      recipientIdField = (
        <input type='hidden' name={fieldName} value={this.props.recipient_id} />
      )
    }

    if (this.state.enable_drop_zone == false) {
      dropzoneHtml = (
        <div>
          <div className='hidden'>
            <FileInput ref={fileInput => this.fileInput = fileInput} multiple accept={this.props.allowed_extensions} uploader={this.state.uploader}>
              <span className='text-underline text-gray cursor-pointer open-selector'>{I18n.t('general.click_here')}</span>
            </FileInput>
          </div>
          <span className='text-danger'>{this.state.file_extension_error.length > 0  ? I18n.t('general.uploader.extension_black_list_error', { file_name: this.state.file_extension_error }): ''}</span>
          <span className='text-danger'>{this.state.single_size_error}</span>
          <span className='text-danger'>{this.state.total_size_error}</span>
        </div>
      )
    } else {
      dropzoneHtml = (
        <Dropzone multiple uploader={this.state.uploader} className=''>
          <span className=''><i className='fine-uploader-dropzone-icon dropzone-icon-lg far fa-upload spacer-right-xxs' /></span>
          <p className='text-subtitle dropzone-uploader-instructions'> </p>
          <span className='text-gray'>{I18n.t('general.uploader.drag_drop')}</span>
          <div>
            <FileInput ref={fileInput => this.fileInput = fileInput} multiple uploader={this.state.uploader}>
              <span className='text-underline text-gray cursor-pointer'>{I18n.t('general.click_here')}</span>
            </FileInput>
            {clioImportLink}
          </div>
          <div className='text-danger'>{this.state.file_extension_error.length > 0  ? I18n.t('general.uploader.extension_black_list_error', { file_name: this.state.file_extension_error }): ''}</div>
          <div className='text-danger'>{this.state.single_size_error}</div>
          <div className='text-danger'>{this.state.total_size_error}</div>
        </Dropzone>
      )
    }

    return (
      <div>
        {dropzoneHtml}
        {recipientIdField}
        {
          this.state.files.map((file, i) => {
            return (
              <ActivityUploaderFile
                uploader={this.state.uploader}
                key={file.id}
                file={file}
                type={this.props.type}
                doc_idx={this.state.doc_base_idx + i}
                doc_collect_item_id={this.props.doc_collect_item_id}
                recipient_idx={this.props.recipient_idx}
                remove={this.removeFile.bind(this, file.id)}
              />
            )
          })
        }

      </div>
    )
  }
}
  
export default ActivityUploader;
