$(document).on('turbolinks:load show.bs.modal', function () {
  updateMdlSelectInput();
  initFloatingInputs();
  initPhoneNumberInput();
  initMultipleSelect();
  initPopovers();

});

function initPopovers() {
  $(".popover-with-html").popover({
    html: true,
    content: function () {
      var content = $(this).attr("data-popover-content");
      return $(content).children(".popover-body").html();
    },
    title: function () {
      var title = $(this).attr("data-popover-content");
      return $(title).children(".popover-heading").html();
    }
  });
}

function initMultipleSelect() {
  // Setup bootstrap-multiselect
  $('.js-bootstrap-multiselect').multiselect({
    enableCaseInsensitiveFiltering: true
  });
}

function initPhoneNumberInput() {
  if ($(".js-phone-number").length) {
    $(".js-phone-number").intlTelInput({
      autoPlaceholder: "polite",
      formatOnInit: true,
      formatOnDisplay: true,
      initialCountry: 'ca',
      preferredCountries: ['ca', 'us', 'gb', 'fr']
    });
  }
}

function updateMdlSelectInput() {
  $('.getmdl-select input').each(function (idx, item) {
    if ($(this).data('mdl-update-input') != null) {
      $(this).on('change', function () {
        var destInput = $($(this).data('mdl-update-input'));
        destInput.val(this.dataset.val).trigger('change');
      });
    }
  });
}

function initFloatingInputs() {
  $(document).on('keyup', 'input', function (e) {
    activateFloatingInput($(this));
  });

  $(document).on('focus', 'input', function () {
    activateFloatingInput($(this));
  });

  $(document).on('blur', 'input', function () {
    removeClassUsedOnInputs($(this));
  });

  let activateFloatingInput = function (el) {
    removeClassUsedOnInputs($(this));
    var inputId = $(el).prop('id');

    // find the label that has that id as a for attr
    var label = $("label[for='" + inputId + "']");

    if (label.length > 0) {
      $(label).addClass('used');
    }
  };

  let removeClassUsedOnInputs = function () {
    $.each($('.form-label-float label'), function (index, el) {
      var inputId = $(el).attr('for');
      if (inputId != null && inputId.length > 0) {
        var targetInput = $('#' + inputId);

        if (targetInput.val() != null && targetInput.val().length <= 0 && !targetInput.is('select')) {
          $(el).removeClass('used');
        } else {
          $(el).addClass('used');
        }
      }
    });
  };

  // Float the labels that need to float when the page loads.
  $.each($('.form-label-float label'), function (index, el) {
    var inputId = $(el).prop('for');
    if (inputId != null && inputId.length > 0) {
      var targetInput = $('#' + inputId);

      if (targetInput.val() != null && targetInput.val().length > 0) {
        $(el).addClass('used');
      }
    }
  });
}

// To prevent negative tenant count by restricting input to digits
$(document).on('keypress', 'input#organization_tenant_count', function (e) {
  return e.metaKey || // cmd/ctrl
    e.which <= 0 || // arrow keys
    e.which == 8 || // delete key
    /[0-9]/.test(String.fromCharCode(e.which)); //numbers
});

$(document).on('click', '.tenant-consent', function () {
  if($(this).is(':checked')){
    $('.save-tenant').attr('data-warning-backup', $('.save-tenant').attr('data-warning'));
    $('.save-tenant').removeAttr('data-warning');
  } else {
    $('.save-tenant').attr('data-warning', $('.save-tenant').attr('data-warning-backup'));
    $('.save-tenant').removeAttr('data-warning-backup');
  }
});

$(document).on('click', '.save-tenant', function (e) {
  if ($('.save-tenant').attr('data-warning')) {
    e.preventDefault();
    e.stopPropagation();

    WarningAlert($('.save-tenant').attr('data-warning'));

    $('.save-tenant').removeAttr('data-warning');
    $('.save-tenant').removeAttr('data-warning-backup');
  }
});

