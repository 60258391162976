import React from "react"
import ReactDOM from "react-dom"

class RecipientEditor extends React.Component {

  constructor(props) {

    super(props);
    var propRecipient = this.props.recipient || {};
    var isCreate = (Object.keys(propRecipient).length == 0);
    var recipient = $.extend(this.createEmptyRecipient(), propRecipient, {
      recipient_consent: (propRecipient.consent == true)
    });

    this.state = {
      recipient: recipient,
      isCreate: isCreate,
      hideEmailMalformedNotice: true
    };
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }

  recipientEmailIsValid(email) {
    if (email == null)
      email = this.state.recipient.email;

    return (email == null || email.trim() == "" || this.validateEmail(email))
  }

  recipientIsValid(recipient) {
    if (recipient == null)
      recipient = this.state.recipient;

    return (recipient != null &&
      recipient.first_name != null && recipient.last_name != null && recipient.first_name.trim() != "" && recipient.last_name.trim() != "" &&
      recipient.email != null && recipient.email.trim() != "") &&
      this.validateEmail(recipient.email)
  }

  createEmptyRecipient() {
    return {
      id: null,
      full_name: '',
      first_name: '',
      last_name: '',
      email: '',
      locale: 'en',
      description: '',
      two_factor_auth: false,
      has_consent: false
    };
  }

  update = (e) => {
    e.preventDefault();
    if (this.recipientIsValid()) {
      this.state.recipient.full_name = this.state.recipient.first_name + " " + this.state.recipient.last_name
      $(ReactDOM.findDOMNode(this)).trigger("react.recipient_editor.updated", [this.state.recipient, this.props.index]);
      this.close();
      $( ".cocoon.table-items" ).trigger( "change" );
    }
  }

  create = (e) => {
    e.preventDefault();
    if (this.recipientIsValid()) {
      this.state.recipient.full_name = this.state.recipient.first_name + " " + this.state.recipient.last_name
      $(ReactDOM.findDOMNode(this)).trigger("react.recipient_editor.created", [this.state.recipient]);
      this.close();
    }
  }

  open() {
    this.$modalNode.modal("show")
    setTimeout(() => { this.$modalNode.find("#recipient-firstname").focus() }, 500);
  }

  close() {
    this.$modalNode.modal("hide")
    ReactDOM.unmountComponentAtNode(this.modalNode.parentNode)
  }

  handleRecipientTextChange(attributeName, e) {
    var hash = {};
    hash[attributeName] = e.target.value;
    if (attributeName == "email") {
      hash["hideEmailMalformedNotice"] = this.recipientEmailIsValid(hash[attributeName]);
    }
    var recipient = $.extend({}, this.state.recipient, hash);

    this.setState({ recipient: recipient, hideEmailMalformedNotice: hash["hideEmailMalformedNotice"] });
  }

  recipientDescriptionBlock() {
    if (this.props.legal_description_enabled) {
      return (
        <div className="form-group">
          <label htmlFor="recipient-description" className="form-label">{I18n.t("recipients.legal_description")}</label>
          <input id="recipient-description" className="form-control" type="text" value={this.state.recipient.description || ''} onChange={this.handleRecipientTextChange.bind(this, "description")} />
        </div>
      );
    }
  }

  render() {
    let updateBtn = null;
    let createBtn = null;

    if (this.state.isCreate) {
      createBtn = (
        <a className="btn btn-main-pronotif" onClick={this.create} disabled={!this.recipientIsValid()} >{I18n.t("form.save")}</a>
      )
    } else {
      updateBtn = (
        <a className="btn btn-main-pronotif" onClick={this.update} disabled={!this.recipientIsValid()} >{I18n.t("form.save")}</a>
      )
    }

    const locales = [[I18n.t("general.locale_en"), "en"], [I18n.t("general.locale_fr"), "fr"], [(I18n.t("general.locale_fr") + " / " + I18n.t("general.locale_en")), "fr_en"]];

    return (
      <div className="modal fade recipient-modal 22" tabIndex="-1" ref={(ref) => { this.modalNode = ref, this.$modalNode = $(ref) }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
              <h4 className="modal-title text-medium text-md">{this.state.isCreate ? I18n.t("recipients.new_title") : I18n.t("recipients.edit_title")}</h4>
            </div>
            <div className="modal-body">
              <form className="form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="recipient-firstname" className="form-label">{I18n.t("recipients.first_name")}</label>
                      <input id="recipient-firstname" className="form-control" type="text" value={this.state.recipient.first_name} onChange={this.handleRecipientTextChange.bind(this, "first_name")} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="recipient-lastname" className="form-label">{I18n.t("recipients.last_name")}</label>
                      <input id="recipient-lastname" className="form-control" type="text" value={this.state.recipient.last_name} onChange={this.handleRecipientTextChange.bind(this, "last_name")} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="recipient-email" className="form-label">{I18n.t("recipients.email")}</label>
                      <input id="recipient-email" className="form-control" type="text" value={this.state.recipient.email} onChange={this.handleRecipientTextChange.bind(this, "email")} />
                      <p className="small" hidden={this.state.hideEmailMalformedNotice}>{I18n.t("recipients.malformed_email")}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div className="recipient-locale-div">
                        <label htmlFor="recipient-locale" className="form-label">{I18n.t("recipients.locale")}</label>
                        <select className="form-control" id="new-recipient-locale" value={this.state.recipient.locale} onChange={this.handleRecipientTextChange.bind(this, "locale")}>
                          {
                            locales.map((locale, i) => <option key={i} value={locale[1]}>{locale[0]}</option>)
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {this.recipientDescriptionBlock()}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {createBtn}
              {updateBtn}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RecipientEditor;
