import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Mutate a copy of data without changing the original source
import update from 'immutability-helper';
import { DataView } from "primereact/components/dataview/DataView";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { BeatLoader } from 'react-spinners';
import Activity from './Activity';
import axios from "axios";

export class ActivitiesList extends Component {

  static defaultProps = {
    loaderColor: '#0056ba'
  }

  static propTypes = {
    loaderColor: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      layout: 'list',
      loading: false,
      sortKey: null,
      sortOrder: null,
      first: 0,

      queryParams: {
        "page[number]": 1,
        "page[size]": 5,
        sort: "-created_at",
        q: ""
      }
    };

  }

  componentDidMount() {
    if (this.props.dataSourceUrl) {
      this.loadData(this.props.dataSourceUrl, this.state.queryParams).then(() => {
        this.setState({
          rows: this.state.activities.length
        })
      })
    }
  }

  onPageChange = (event) => {
    let currentPage = event.page + 1; // index of the page array (zero based)

    let newState = update(this.state, { queryParams: { "page[number]": { $set: currentPage } } });
    this.setState(newState);

    this.loadData(this.props.dataSourceUrl, newState.queryParams).then(() => {
      this.setState({
        first: event.first,
        rows: event.rows
      })
    })
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({ loading: true });

    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then((response) => {
          let data = response.data.data || []
          let meta = response.data.meta || {}

          this.setState({
            totalRecords: meta.pagination.total_count,
            totalPages: meta.pagination.total_pages,
            activities: data,
          });

          this.setState({ loading: false })
        })
        .catch((error) => {
          console.log("Error loading data for datagrid", error);
        }).finally(function () {
          this.setState({ loading: false });
        }.bind(this))

    return promise;
  }

  showPaginator = () => {
    return (this.props.showPagination == "true" && this.state.totalPages > 1)
  }

  showDetails = () => {
    return (this.props.showDetails)
  }

  renderListItem = (activity) => {
    return (
      <Activity activity={activity} key={activity.id} showDetails={this.props.showActivityDetails} />
    )
  }

  render() {
    let paginator;

    if (this.showPaginator()) {
      paginator = <Paginator first={this.state.first} rows={this.state.rows} totalRecords={this.state.totalRecords} onPageChange={this.onPageChange} />
    }
    else {
      paginator = null;
    }

    return (
      <React.Fragment>
        <ThemeProvider theme={this.props.theme}>
          <DataView loading={this.state.loading} value={this.state.activities} layout={this.state.layout}
            itemTemplate={this.renderListItem} rows={this.state.rows} sortOrder={this.state.sortOrder} sortField={this.state.sortField} emptyMessage={""} />

          {paginator}

          <BeatLoader
            css={{ display: 'block', margin: '0 auto', textAlign: 'center' }}
            sizeUnit={"px"}
            size={15}
            color={this.props.loaderColor}
            loading={this.state.loading}
          />
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

export default ActivitiesList;
