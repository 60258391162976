import styled from 'styled-components'

export const FullName      = styled.div`
  font-size: 22px;
  font-weight: 200;

  span {
    font-weight: 400;
  }
`
export const CampaignName  = styled.h3`
  font-size: 14px;
  font-weight: 300;
  font-style: italic;

  span {
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-weight: 400;
  }
`
export const AmountDue     = styled.div`
  span {
    color: red;
    font-weight: bold;
  }
`
export const AccountNumber = styled.div`
  span {
    font-weight: bold;
  }
`
export const Container     = styled.div`
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-style: normal;
  text-align: left;
  border-bottom: 1px solid rgb(226, 232, 237);
  padding: 20px;
  margin-bottom: 20px;
`