
$(document).on('turbolinks:load', function () {
  $(document).on('click', '#js-org-import-fields input[type="checkbox"]', function (e) {
    if(e.target.checked && e.target.id.includes('required')){
      $(`#${e.target.id.replace('required', 'visible')}`).prop('checked', true);
    }
    else if(!e.target.checked && e.target.id.includes('visible')) {
      let chk = $(`#${e.target.id.replace('visible', 'required')}`);
      if(!chk.is('[disabled=disabled]')) {
        chk.prop('checked', false);
      }
    }
  });
});
