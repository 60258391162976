import React, { Component } from 'react';

class ModalCredit extends Component {
  open = () => {
    this.$modalNode.modal('show');
  };

  redirectTo = (url) => {
   window.location.href = url;
  }

  render() {
    const creditAmount = I18n.t('general.credit_amount', { count: this.props.current_credits })
    return (
      <div
        className='modal fade'
        tabIndex='-1'
        ref={ref => {
        (this.modalNode = ref), (this.$modalNode = $(ref));
      }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <button type='button' className='close p-4' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
            <div className='modal-header' style={{ display: 'flex', 'flexDirection': 'row' }}>
              <h5 className='modal-title'>
                {I18n.t('general.title_credit', {
                  current_credits: this.props.current_credits,
                  credit_amount: creditAmount
                })}
              </h5>
              <span style={{ 'flexGrow': 1 }} />
            </div>
            <div className='modal-body'>
              <div className='stars-block d-flex justify-content-center align-items-center mb-5'>
                <p>{I18n.t('general.body_credit', {credit_price: this.props.credit_price} )}</p>
              </div>

            </div>
            <div className='modal-footer'>
              <button type='button' data-dismiss='modal' onClick={() => this.redirectTo('/organization/invoices')} className='btn btn-main-pronotif'>{I18n.t('general.cta_credit')}</button>
              <button type='button' data-dismiss='modal' className='btn btn-main-pronotif'>{I18n.t('general.cta_credit_2')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalCredit
