import React from 'react';
import { Calendar as BaseCalendar } from 'primereact/components/calendar/Calendar';
import styled from "styled-components"

export const Calendar = styled(BaseCalendar)`
  /* Styles go here */
  .p-datepicker td {
    padding: 0.4em;
  }
`
export default Calendar