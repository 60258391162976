import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
`
export const Body      = styled.div`
  background-color: ${props => props.isOutgoing ? '#e3f3ff' : '#F0F4F7'};
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  width: 100%;

  p {
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #1C2434;
    line-height: 22px;
  }
`
export const SentAt    = styled.div`
  color: #868E9A;
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-rendering: optimizeLegibility;
  text-align: center;
  padding-bottom: 5px;
`
export const Sender    = styled.div`
  text-align: ${props => props.isOutgoing ? 'right' : 'left'};
  line-height: 18px;
  font-family: 'Roboto-Light', 'Roboto Light', 'Roboto', sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  color: #868E9A;
`