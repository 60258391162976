$(function() {
  
  $(document).on("keyup", ".js-input-checksum", function() {
    var $checksum = $(this).parent().find(".js-checksum");
    var $formGroup = $(this).parents(".form-group");
  
    if ($(this).val().length > 0) {
      $formGroup.removeClass("has-feedback-hidden");
  
      if ($(this).val().toLowerCase() == $checksum.val().toLowerCase()) {
        $formGroup.removeClass("has-error");
        $formGroup.addClass("has-success");
      } else {
        $formGroup.removeClass("has-success");
        $formGroup.addClass("has-error");
      }
    }
  });
  
  $(document).on('change','.document-upload-input', function(e) {
    //var fileName = $(this).val().split('\\').pop();
    var fileNames = "";
    $.each(this.files, function(index, file){
      if(fileNames.length == 0){
        fileNames = file.name; 
      }else{
        fileNames = fileNames + ", " + file.name;
      }
    });
    
    var container = $(this).closest(".documents-container");
    var newDocumentRow = container.find('.document-list-item').first();
    
    $(newDocumentRow).find('.document-item-filename').html(fileNames);
    
  });
  
  var displayNoContent = function(){
    if($(".documents-container .nested-fields").length == 0){
      $('.no-documents').removeClass('hidden');
    }else{
      $('.no-documents').addClass('hidden');
    }
  };
  
  $('.documents-container')
    .on('cocoon:after-insert', function(e, insertedItem) {
      displayNoContent();
    })
    .on('cocoon:after-remove', function(e, insertedItem) {
      displayNoContent();
    });
  
});
