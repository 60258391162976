import React from "react"


//NO LONGER USED - ONLY FOR REFERENCE

class RecipientNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient: {
        first_name: "",
        last_name: "",
        email: "",
        locale: "",
        description: "",
        two_factor_auth: false,
        has_consent: false
      },
      tenant_lookup: (props.tenant_lookup_url != null && props.tenant_lookup_url.length > 0) ? true : false,
      link_with_tenant: false
    };
  }

  open = () => {
    var $node = $(this.modalNode)
    $node.modal("show");
    this.setState({
      recipient: {
        first_name: '',
        last_name: '',
        email: '',
        locale: 'en',
        description: '',
        two_factor_auth: false,
        has_consent: false
      }
    });
  }

  close = () => {
    var $node = $(this.modalNode)
    $node.modal("hide");
  }

  performSeachTenant = () => {
    $.getJSON(this.props.tenant_lookup_url + "?q=" + this.state.recipient.email, (response) => {
      var tenant_id = null;
      var link_with_tenant = false;
      if (response.error != null && response.error.length > 0) {
        alert(response.error);
        //this.props.handle_clio_api_errors(response.error);
      } else if (response.tenants != null && response.tenants.length > 0) {
        tenant_id = response.tenants[0].id;
        link_with_tenant = true;
      }
      this.state.recipient.tenant_id = tenant_id;
      this.setState({ recipient: this.state.recipient, link_with_tenant: link_with_tenant })
    });

  }

  handleTenantLookupEmail = (e) => {
    if (this.state.tenant_lookup && this.validateEmail()) {
      this.performSeachTenant();
    }
  }

  handleChangeFirstName = (e) => {
    this.setState(prevState => ({
      recipient: {
        ...prevState.recipient,
        first_name: e.target.value
      }
    }))
  }

  handleChangeLastName = (e) => {
    this.setState(prevState => ({
      recipient: {
        ...prevState.recipient,
        last_name: e.target.value
      }
    }))
  }

  handleChangeEmail = (e) => {
    var recipient = this.state.recipient;
    recipient.email = e.target.value;
    if (this.state.tenant_lookup) {
      recipient.tenant_id = null;

    }

    this.setState({ recipient: recipient, link_with_tenant: false });
  }

  handleChangeDescription = (e) => {
    this.setState(prevState => ({
      recipient: {
        ...prevState.recipient,
        description: e.target.value
      }
    }))
  }

  handleChangeLocale = (e) => {
    this.setState(prevState => ({
      recipient: {
        ...prevState.recipient,
        locale: e.target.value
      }
    }))
  }

  handleChangeLinkedWithTenant = (e) => {
    this.setState({ link_with_tenant: e.target.checked });
  }

  validateEmail = () => {
    var re = /^(([^<>()\[\]\\.,;:?\s@"]+(\.[^<>()\[\]\\.,;:?\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(this.state.recipient.email.toLowerCase());
  }

  submitDisabled = () => {
    var retVal = true;

    var missingContent = (this.state.recipient.email.length > 0 && this.state.recipient.first_name.length > 0 && this.state.recipient.last_name.length > 0) ? false : true;

    if (!missingContent) {
      retVal = !this.validateEmail();
    }

    return retVal;
  }

  saveRecipientNoLink = (e) => {
    this.state.recipient.tenant_id = null;
    this.saveRecipient(e)
  }

  saveRecipient = (e) => {
    e.preventDefault();

    var recipient = this.state.recipient;
    $('#recipients-add-new').click();
    var fields = $('#recipients .nested-fields:last');

    var recipientEmailField = $(fields).find('.recipient-email');
    var recipientFullNameField = $(fields).find('.recipient-fullname');
    var recipientFirstNameField = $(fields).find('.recipient-firstname');
    var recipientLastNameField = $(fields).find('.recipient-lastname');
    var recipientDescriptionField = $(fields).find('.recipient-description');
    var recipientLocaleField = $(fields).find('.recipient-locale');
    var recipientTenantIdField = $(fields).find('.recipient-tenant-id');

    recipientEmailField.val(recipient.email);
    recipientFullNameField.val(recipient.first_name + " " + recipient.last_name);
    recipientFirstNameField.val(recipient.first_name);
    recipientLastNameField.val(recipient.last_name);
    recipientDescriptionField.val(recipient.description);
    recipientLocaleField.val(recipient.locale);
    if (recipientTenantIdField.length > 0 && recipient.tenant_id != null) {
      recipientTenantIdField.val(recipient.tenant_id);
    }
    var recipient_item = $('#recipients-list-table').find('.recipient-list-item').last();

    $(recipient_item).find('.recipient-item-fullname').html(recipient.first_name + " " + recipient.last_name);
    $(recipient_item).find('.recipient-item-firstname').html(recipient.first_name);
    $(recipient_item).find('.recipient-item-lastname').html(recipient.last_name);
    $(recipient_item).find('.recipient-item-email').html(recipient.email);
    $(recipient_item).find('.recipient-item-description').html(recipient.description);

    var $reciplocale = $(recipient_item).find('.recipient-item-locale');

    $reciplocale.find('option').each(function () {
      if ($(this).val() == recipient.locale) {
        $(this).prop('selected', true);
      }
    });

    //$("#recipients-list-table").trigger('evaluate-locales');

    var twoFactorAuthIcon = recipient.two_factor_auth ? 'fa-check text-green' : 'fa-close text-red';
    $(recipient_item).find('.recipient-item-factor-auth').html('<i class="fa ' + twoFactorAuthIcon + '"></i>');

    var consentIcon = recipient.has_consent ? 'fa-check text-green' : 'fa-close text-red';
    $(recipient_item).find('.recipient-item-consent-received').html('<i class="fa ' + consentIcon + '"></i>');

    this.close();
  }

  recipientDescriptionBlock = () => {
    if (this.props.legal_description_enabled) {
      return (
        <div className="col-xs-12 col-sm-6">
          <div className="input-group">
            <label htmlFor="new-recipient-description" className="form-label">{I18n.t("recipients.legal_description")}</label>
            <input id="new-recipient-description" className="form-control" type="text" value={this.state.recipient.description || ''} onChange={this.handleChangeDescription} />
          </div>
        </div>
      );
    }
  }

  render() {

    let linkedWithTenantNotice = null;
    let linkedWithTenantCheckbox = null;
    const locales = [[I18n.t("general.locale_en"), "en"], [I18n.t("general.locale_fr"), "fr"], [(I18n.t("general.locale_fr") + " / " + I18n.t("general.locale_en")), "fr_en"]];

    if (this.state.tenant_lookup && this.state.recipient.tenant_id != null && this.state.recipient.tenant_id.length > 0) {
      linkedWithTenantNotice = (
        <p className="small">I18n.t("recipients.link_with_tenant_notice")</p>
      )

      linkedWithTenantCheckbox = (
        <div className="col-xs-12 col-sm-6">
          <div className="input-group">
            <label htmlFor="new-recipient-linked-with-tenant" className="form-label used">{I18n.t("recipients.link_with_tenant")}</label>
            <input id="new-recipient-linked-with-tenant" className="form-control" type="checkbox" checked={this.state.link_with_tenant} onChange={this.handleChangeLinkedWithTenant} />
          </div>
        </div>
      )
    }

    return (
      <div className="modal fade new-recipient-modal 22" tabIndex="-1" ref={(ref) => this.modalNode = ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
              <h4 className="modal-title text-medium text-md">{I18n.t("activities.recipients.new")}</h4>
            </div>
            <div className="modal-body p-t-0">
              <div className="row">
                <div className="col-xs-12">
                  <form className="form">
                    <div className="row">

                      <div className="col-xs-12 col-sm-6">
                        <div className="input-group">
                          <label htmlFor="new-recipient-firstname" className="form-label">{I18n.t("recipients.first_name")}</label>
                          <input id="new-recipient-firstname" className="form-control" type="text" value={this.state.recipient.first_name} onChange={this.handleChangeFirstName} />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-6">
                        <div className="input-group">
                          <label htmlFor="new-recipient-lastname" className="form-label">{I18n.t("recipients.last_name")}</label>
                          <input id="new-recipient-lastname" className="form-control" type="text" value={this.state.recipient.last_name} onChange={this.handleChangeLastName} />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-6">
                        <div className="input-group">
                          <label htmlFor="new-recipient-email" className="form-label">{I18n.t("recipients.email")}</label>
                          <input id="new-recipient-email" className="form-control" type="text" value={this.state.recipient.email} onChange={this.handleChangeEmail} onBlur={this.handleTenantLookupEmail} />
                          {linkedWithTenantNotice}
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-6">
                        <div className="input-group">
                          <div className="form-group">
                            <label htmlFor="new-recipient-locale" className="used form-label">{I18n.t("recipients.locale")}</label>
                            <select className="form-control" id="new-recipient-locale" value={this.state.recipient.locale} onChange={this.handleChangeLocale}>
                              {
                                locales.map((locale, i) => <option key={i} value={locale[1]}>{locale[0]}</option>)
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      {linkedWithTenantCheckbox}
                      {this.recipientDescriptionBlock()}

                      <div className="col-xs-12 spacer-top-xs">
                        <div className="text-right">
                          <button type="submit" id="create-new-recipient" onClick={this.saveRecipient} disabled={this.submitDisabled()} className="btn btn-main btn-main-pronotif">{I18n.t("recipients.add")}</button>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecipientNew;
