import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { Spinner, spinnerService } from '@simply007org/react-spinners';

class DocCollectItemsList extends Component {
  static defaultProps = {
    loadingIcon: 'far fa-spinner fa-spin page-load-icon'
  }

  static propTypes = {
    dataSourceUrl: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    loadingIcon: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = { 
      docCollectItems: [],
      queryParams: {
        "page[size]": 1000
      }
    };
  }

  componentDidUpdate(prevProps) {    
    if (this.props.visible && !prevProps.visible) {
      this.loadData(this.props.dataSourceUrl, this.state.queryParams)     
    }   
  }

  loadData(dataSourceUrl, query = {}) {
    spinnerService.show('docCollectSpinner');

    let promise = 
      axios.get(dataSourceUrl, {params: query})
        .then((response) => {
          let data = response.data.data || []
          let meta = response.data.meta || {}

          this.setState({
            totalRecords: meta.pagination.total_count,
            totalPages: meta.pagination.total_pages,
            docCollectItems: data
          });

          spinnerService.hide('docCollectSpinner');
        })
        .catch((error) => {
          spinnerService.hide('docCollectSpinner');
          console.log("Error loading data: ", error);
        })

    return promise;      
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12 mh-scroll-container">
          
          <Spinner name="docCollectSpinner">          
            <div className="p-datatable-loading">
              <div className="p-datatable-loading-overlay p-component-overlay"></div>
              <div className="p-datatable-loading-content">
                <i className={classNames('p-datatable-loading-icon', this.props.loadingIcon)}></i>  
              </div>
            </div>
          </Spinner>

          {
              this.state.docCollectItems.map((item, i) => {
                return (
                  <div key={item.id} className="row">
                    <div className="col-xs-12 spacer-bottom-xs">
                      <table className="table table-hover table-light-td-bt">
                        <thead>
                          <tr className="">
                            <th className="" colSpan="2">
                              <span className="font-size-15 block text-dark-gray">{item.doc_name}</span>
                              <span className="font-size-14 block text-gray">{item.description}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (item.document != null && item.document.length > 0) ?
                              item.document.map((doc, i) => {
                                return (
                                  <tr key={doc.id} className="js-clickable-row" data-href={ doc.file_url } target="_blank">
                                    <td>{doc.file_name}</td>
                                    <td className="text-right">{I18n.t("people.uploaded_on")}&nbsp;{doc.created_at_formatted}</td>
                                  </tr>
                                )
                              })
                            :
                              (
                                <tr className="">
                                  <td className="text-center" colSpan="2">{ I18n.t("blank_state.general.title") }</td>
                                </tr>
                              )
                          }
                        </tbody>
                      </table>
                      
                    </div>
                  </div>
                )
              }
            )
          }
        </div>
      </div>
    )
  }
}  

export default DocCollectItemsList;