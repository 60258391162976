import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Mutate a copy of data without changing the original source
import update from 'immutability-helper';
import axios from 'axios';

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Paginator } from 'primereact/components/paginator/Paginator';

import SearchFilter from './SearchFilter';

class PeopleTable extends Component {

  static defaultProps = {
    loaderColor: '#0056ba'
  }

  static propTypes = {
    dataSourceUrl: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      first: 0,
      totalRecords: 0,

      queryParams: {
        "page[number]": 1,
        sort: "last_name",
        q: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.dataSourceUrl) {
      this.loadData(this.props.dataSourceUrl, { "page[number]": 1 }).then(() => {
        this.setState({
          rows: this.state.data.length
        })
      })
    }
  }

  onFilterChange = (event) => {
    if (this.state.filterTimerId) {
      clearTimeout(this.state.filterTimerId)
    }

    let filterValue = event.target.value;

    let filterTimerId = setTimeout(() => {
      let newState = update(this.state, { queryParams: { q: { $set: filterValue } } });
      this.setState(newState);

      this.loadData(this.props.dataSourceUrl, newState.queryParams);
    }, 250);

    this.setState({ filterTimerId: filterTimerId })
  }

  onPageChange = (event) => {
    let currentPage = event.page + 1; // index of the page array (zero based)    

    let newState = update(this.state, { queryParams: { "page[number]": { $set: currentPage } } });
    this.setState(newState);

    this.loadData(this.props.dataSourceUrl, newState.queryParams).then(() => {
      this.setState({
        first: event.first,
        rows: event.rows
      })
    })
  }

  onSort = (event) => {
    let sortParam = (event.sortOrder == -1) ? ['-', event.sortField].join('') : event.sortField;

    let newState = update(this.state, { queryParams: { sort: { $set: sortParam } } });
    this.setState(newState);

    this.loadData(this.props.dataSourceUrl, newState.queryParams).then(() => {
      this.setState({
        sortField: event.sortField,
        sortOrder: event.sortOrder
      })
    })
  }

  onRowClick = (event) => {
    var path = ('/people/' + event.data.id);
    window.location.href = path;
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({
      loading: true,
      emptyMessage: ""
    });

    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then((response) => {
          let data = response.data.data || []
          let meta = response.data.meta || {}

          this.setState({
            totalRecords: meta.pagination.total_count,
            totalPages: meta.pagination.total_pages,
            pageSize: meta.pagination.per_page,
            rows: data.length,
            data: data,
            loading: false,
            emptyMessage: I18n.t("blank_state.general.title")
          });
        })
        .catch((error) => {
          this.setState({ loading: false })
          console.log("Error loading data for datagrid", error);
        })

    return promise;
  }

  fullNameColumn(rowData, column) {
    return (
      <span>
        <strong className="">{rowData.last_name}</strong>, {rowData.first_name}
      </span>
    )
  }

  emailColumn(rowData) {
    if (!rowData.email_deliverable) {
      return (
        <a className="text-red red-link" href={"/people/" + rowData.id + "/edit"} data-remote-modal="true">
          <i className="fa fa-warning m-r-5"></i>{rowData.email}
        </a>
      )
    } else {
      return (
        <span>
          {rowData.email}
        </span>
      )
    }
  }

  activitiesCountColumn(rowData, column) {
    return (
      <span>
        {rowData.activities_count}
      </span>
    )
  }

  actionsColumn(rowData, column) {
    // FIXME: pass paths in serializer & handle delete w axios
    return (
      <div>
        <a className="action-link spacer-right-xs text-navy" href={"/people/" + rowData.id + "/edit"} onClick={(event) => event.stopPropagation()} data-remote-modal="true" data-turbolinks="false">
          <i className="fal fa-edit"></i>
        </a>
        <a className="action-link text-navy" href={"/people/" + rowData.id} data-method="delete" data-confirm={I18n.t("people.delete_confirm_msg", { contact_name: rowData.full_name })}>
          <i className="fal fa-trash-alt"></i>
        </a>
      </div>
    )
  }

  render() {
    let counter;

    if (!this.state.loading) {
      counter = <span className="text-gray text-regular font-size-13">{I18n.t("people.x_contacts_counter", { count: this.state.totalRecords })}</span>
    } else {
      counter = <span className="font-size-13">&nbsp;</span>
    }

    let header = (
      <div className="row">
        <div className="col-xs-12 text-left spacer-bottom-xxs">
          <SearchFilter onSearchChange={this.onFilterChange} />
        </div>
        <div className="col-xs-12 text-left spacer-top-xxs">
          {counter}
        </div>
      </div>
    );

    let paginator;

    if (this.props.showPagination == "true" && this.state.totalPages > 1) {
      paginator = <Paginator first={this.state.first} rows={this.state.pageSize} totalRecords={this.state.totalRecords} onPageChange={this.onPageChange} />
    }
    else {
      paginator = null;
    }

    return (
      <React.Fragment>
        <DataTable
          header={header}
          value={this.state.data}
          responsive={true}
          loading={this.state.loading}

          // Sorting
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
          onSort={this.onSort}
          onRowClick={this.onRowClick}
          tableClassName={'table'}
          emptyMessage={this.state.emptyMessage}
        >

          <Column body={this.fullNameColumn} field="full_name_formatted" header={I18n.t("form.name")} sortField="last_name" sortable={true} filterMatchMode="contains" headerClassName="text-left" />
          <Column body={this.emailColumn} field="email_formatted" header={I18n.t("form.email")} sortField="email" sortable={true} filterMatchMode="contains" headerClassName="text-left" className="hidden-xs" />
          <Column body={this.activitiesCountColumn} field="activity_count_formatted" sortField="activity_count" header={I18n.t("general.activities")} sortable={false} filterMatchMode="contains" style={{ textAlign: 'center' }} className="hidden-xs" />
          <Column body={this.actionsColumn} style={{ textAlign: 'right' }} />
        </DataTable>

        {paginator}
      </React.Fragment>
    );
  }
}

export default PeopleTable;