import React from "react"

const STATUS = {
  SELECTED: 1, // Selected file
  UPLOADING: 2, // uploading file
  UPLOADED: 3, // uploaded file
}

export class ClioDocImporter extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.clio_matters_search_url) console.error("No `clio_matters_search_url` property found.");
    if (!this.props.clio_docs_url) console.error("No `clio_docs_url` property found.");
    if (!this.props.clio_add_doc_url) console.error("No `clio_add_doc_url` property found.");
    if (!this.props.activity_id) console.error("No `activity_id` property found.");
    if (!this.props.docs_uploaded) console.error("No `docs_uploaded` property found.");

    this.state = {
      selected_docs: [],
      error_msg: ""
    }

    this.current_docs = [];
  }

  componentDidMount() {
    $(document).trigger("activities.form.clio_doc_importer_mounted");
    this._search.performSeach("");
  }

  componentDidUpdate() {
    /*$(ReactDOM.findDOMNode(this))
      .trigger("react.activity_uploader.file_list_updated");*/
  }

  handleClioApiError = (errorMessage) => {
    this.setState({ error_msg: errorMessage })
  }

  matterListChanged = (newMatters) => {
    this._matters_list.updateMatters(newMatters);
    this._docs_list.updateDocuments([]);
  }

  searchDocuments = (matter_id) => {
    $.getJSON(this.props.clio_docs_url + "?matter_id=" + matter_id, (response) => {
      if (response.error != null && response.error.length > 0) {
        this.handleClioApiError(response.error);
      }
      this._docs_list.updateDocuments(response.clio_documents);
      this.current_docs = response.clio_documents;
    });
  }

  matterSelected = (matter) => {
    this.searchDocuments(matter.id);
  }

  documentsSelected = (documents) => {
    this.setState({ selected_docs: documents })
  }

  performImport = () => {
    if (this.state.selected_docs.length > 0) {
      var ids = this.state.selected_docs.map((doc, i) => { return doc.id });
      var params = "?doc_ids=" + ids.join(",") + "&activity_id=" + this.props.activity_id;
      if (this.props.recipient_id) {
        params += "&recipient_id=" + this.props.recipient_id
      }

      this.current_docs.forEach((doc, i) => {
        if (ids.indexOf(doc.id) >= 0) {
          doc.status = STATUS.UPLOADING
        }
      })
      this._docs_list.updateDocuments(this.current_docs);

      $.getJSON(this.props.clio_add_doc_url + params, (response) => {
        if (response.error != null && response.error.length > 0) {
          this.handleClioApiError(response.error);
        }

        this.current_docs.forEach((doc, i) => {
          if (ids.indexOf(doc.id) >= 0) {
            doc.status = STATUS.UPLOADED
          }
        })
        this._docs_list.updateDocuments(this.current_docs);
        this.props.docs_uploaded(response.added_documents);
      });
    }
  }

  open = () => {
    var $node = $(this.modalNode)
    $node.modal("show")
  }

  close = () => {
    var $node = $(this.modalNode)
    $node.modal("hide")
  }

  destroy = () => {
    var $node = $(this.modalNode)
    $node.remove();
  }

  render() {
    let alertLine = null;
    if (this.state.error_msg) {
      alertLine = (
        <div className="row">
          <div className="spacer-top-xxs">
            <div className="col-md-12">
              <div className="alert alert-danger fade in" role="alert" dangerouslySetInnerHTML={{ __html: this.state.error_msg }}>

              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="modal fade clio-doc-importer clio-files-import" tabIndex="-1" role="dialog" ref={(ref) => this.modalNode = ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label={I18n.t("general.close", { defaultValue: "Close" })}><span>&times;</span></button>
              <h4 className="modal-title">
                {I18n.t("activities.sections.documents.title_clio_short", { defaultValue: "Import from" })}
                &nbsp;<img src="/clio.png" alt="Clio" width="80" className="clio-logo-modal" />
              </h4>
            </div>
            <div className="modal-body">
              {alertLine}
              <div className="row">
                <div className="">
                  <div className="col-sm-10 col-sm-offset-1">
                    <ClioMattersSearch ref={(search) => this._search = search} onChange={this.matterListChanged} handle_clio_api_errors={this.handleClioApiError} clio_matters_search_url={this.props.clio_matters_search_url} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <div className="clio-cases col-xs-12">
                    <ClioMattersList ref={(matters_list) => this._matters_list = matters_list} clio_matters_selected={this.matterSelected} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="spacer-top-xxs">
                  <div className="clio-files col-xs-12">
                    <ClioDocumentsList ref={(docs_list) => this._docs_list = docs_list} clio_documents_selected={this.documentsSelected} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="spacer-top-xxs">
                  <div className="col-xs-12 text-right">
                    <button type="button" className="btn btn-main-pronotif" disabled={(this.state.selected_docs.length == 0)} onClick={this.performImport}>
                      {I18n.t("general.add_files", { defaultValue: "Import documents" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ClioDocumentsList extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.clio_documents_selected) console.error("No `clio_documents_selected` property found.");

    var documents = [];

    if (this.props.documents) {
      documents = this.props.documents;
    }

    this.state = {
      documents: documents,
    };

  }

  getItemClass = (doc) => {
    var retVal = 'list-group-item';
    if (doc.status == STATUS.SELECTED || doc.status == STATUS.UPLOADING) {
      retVal += " active"
    }

    return retVal;
  }

  updateDocuments = (documents) => {
    this.setState({
      documents: documents,
    });
    this.props.clio_documents_selected(this.getSelectedDocs());
  }

  toggleSelection = (doc, e) => {
    doc.status = (doc.status == STATUS.SELECTED) ? null : STATUS.SELECTED;

    this.setState({
      documents: this.state.documents,
    });

    this.props.clio_documents_selected(this.getSelectedDocs());
  }

  getSelectedDocs = () => {
    var retDocs = [];
    var documents = this.state.documents;
    for (let i in documents) {
      if (documents[i].status === STATUS.SELECTED) {
        retDocs.push(documents[i]);
      }
    }
    return retDocs;
  }


  getSelectionCount = () => {
    return this.getSelectedDocs().length;
  }

  selectAll = () => {
    var documents = this.state.documents;
    for (let i in documents) {
      documents[i].status = STATUS.SELECTED;
    }
    this.setState({
      documents: documents,
    });
    this.props.clio_documents_selected(this.getSelectedDocs());
  }

  unSelectAll = () => {
    var documents = this.state.documents;
    for (let i in documents) {
      documents[i].status = null;
    }
    this.setState({
      documents: documents,
    });
    this.props.clio_documents_selected(this.getSelectedDocs());
  }

  render() {

    let docs = (
      <li className='list-group-item text-center' id='empty-list-item'>{I18n.t("activities.sections.documents.clio_empty_documents", { defaultValue: "No documents found" })}</li>
    );

    if (this.state.documents.length > 0) {
      docs = (
        this.state.documents.map((doc, i) => {
          let uploadedIcon = null;

          if (doc.status == STATUS.UPLOADED) {
            uploadedIcon = (
              <i className="fa fa-check text-green pull-right"></i>
            )
          } else if (doc.status == STATUS.UPLOADING) {
            uploadedIcon = (
              <i className="fa fa-circle-o-notch fa-spin pull-right"></i>
            )
          }

          return (
            <li key={doc.id} className={this.getItemClass(doc)} onClick={this.toggleSelection.bind(this, doc)}><span>{doc.filename} {uploadedIcon}</span></li>
          )
        })
      )
    }

    return (
      <div>
        <div className="spacer-bottom-xxs clearfix">
          <div className="strong pull-left">{I18n.t("general.files", { defaultValue: "Files" })} <strong className="text-teal">(<span id="clio-doc-count">{this.getSelectionCount()}</span> {I18n.t("general.selected", { defaultValue: "Selected" })})</strong></div>
          <div className="strong pull-right">
            <a id="clio-docs-select-all" className="spacer-right-xxs" onClick={this.selectAll} >{I18n.t("general.select_all", { defaultValue: "Select all" })}</a>
            &nbsp;&nbsp;
            <a id="clio-docs-unselect-all" onClick={this.unSelectAll} >{I18n.t("general.unselect_all", { defaultValue: "Unselect all" })}</a>
          </div>
        </div>
        <ul className="clio-documents-container list-group list-group-sm">
          {docs}
        </ul>
      </div>
    )

  }

}

export class ClioMattersList extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.clio_matters_selected) console.error("No `clio_matters_selected` property found.");

    var matters = [];

    if (this.props.matters) {
      matters = this.props.matters;
    }

    this.state = {
      matters: matters,
      selected_matter_id: null
    };

  }

  getItemClass(matter) {
    var retVal = 'list-group-item';
    if (matter.id == this.state.selected_matter_id) {
      retVal += " active"
    }
    return retVal;
  }

  getNumMatterResults() {
    return this.state.matters.length;
  }

  updateMatters(matters) {

    this.setState({
      matters: matters,
      selected_matter_id: null
    });
  }

  matterClick(matter, e) {

    var selected_matter_id = matter.id;

    this.setState({
      selected_matter_id: selected_matter_id
    });

    this.props.clio_matters_selected(matter);
  }

  render() {

    let matters = (
      <li className='text-center list-group-item' id='empty-list-item'>{I18n.t("activities.sections.documents.clio_empty_matters", { defaultValue: "Cases" })}</li>
    )

    if (this.state.matters.length > 0) {
      matters = (
        this.state.matters.map((matter, i) => {
          return (
            <li key={matter.id} className={this.getItemClass(matter)} onClick={this.matterClick.bind(this, matter)}><span>{matter.name}</span></li>
          )
        })
      )
    }

    return (
      <div className="">
        <div className="spacer-bottom-xxs">
          {I18n.t("activities.sections.documents.matters_list_title", { defaultValue: "Matters" })}
          <strong className="text-teal">&nbsp;({this.getNumMatterResults()}&nbsp;{I18n.t("general.results")})</strong>
        </div>
        <ul className="clio-matters-container list-group list-group-sm">
          {matters}
        </ul>
      </div>
    )
  }
}


const WAIT_INTERVAL = 500;

export class ClioMattersSearch extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.clio_matters_search_url) console.error("No `clio_matters_search_url` property found.");
    if (!this.props.handle_clio_api_errors) console.error("No `handle_clio_api_errors` property found.");

    var search_text = "";

    if (this.props.search_text) {
      search_text = this.props.search_text;
    }

    this.state = {
      search_text: search_text,
      matters: [],
      last_search: ''
    };
  }

  componentWillMount() {
    this.timer = null;
  }

  performSeach = () => {
    var searchT = this.state.search_text;
    $.getJSON(this.props.clio_matters_search_url + "?q=" + searchT, (response) => {
      if (response.error != null && response.error.length > 0) {
        this.props.handle_clio_api_errors(response.error);
        this.setState({ matters: [] })
        this.props.onChange(this.state.matters);
      } else {
        this.setState({ matters: response.clio_matters })
        this.props.onChange(this.state.matters);
      }
    });

    this.setState({ last_search: searchT });
  }

  handleChangeSearch = (e) => {
    clearTimeout(this.timer);

    this.setState({ search_text: e.target.value });

    this.timer = setTimeout(this.performSeach, WAIT_INTERVAL);
  }

  render() {
    return (
      <div className="form-label-float">
        <label className="sr-only form-label" htmlFor="matters-search">{I18n.t("activities.sections.documents.clio_matters_search")}</label>
        <input type="text" onChange={this.handleChangeSearch} id="matters-search" className="form-control-plain" />
      </div>
    );
  }

}


export default ClioDocImporter;

