import React, {useState} from 'react'
import SmsConversation   from '../SmsConversation'
import SmsTable          from '../SmsTable'
import {Container}       from './SmsInbox.styles'

const SmsInbox = props =>
  <Container>
    <SmsTable {...props} />
    <SmsConversation debtorId={props.debtorId} />
  </Container>

export default SmsInbox

