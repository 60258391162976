import React from 'react';
import ReactDOM from 'react-dom';
import { debounce } from 'lodash';

class RecipientListEditor extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.create_url && !this.props.update_url)
      console.error('No `create_url or update_url` property found.');

    const recipients = [];
    const undeliverables = [];

    if (this.props.recipients) {
      this.props.recipients.forEach(recipient => {
        recipients.push({
          id: recipient.id,
          person_id: recipient.person_id,
          full_name: recipient.full_name,
          first_name: recipient.first_name,
          last_name: recipient.last_name,
          email: recipient.email,
          description: recipient.description,
          recipient_list_member_id: recipient.recipient_list_member_id,
          locale: recipient.locale,
          deliverable: recipient.deliverable,
          isDeleted: false,
          isValid: this.isRecipientValid(recipient),
          recipient_consent: recipient.consent == true
        });
        if (recipient.deliverable === false) {
          undeliverables.push(recipient.full_name);
        }
      });
    }

    if (recipients.length == 0) {
      recipients.push(this.createEmptyRecipient());
    }

    this.state = {
      name: this.props.name || '',
      recipients,
      undeliverables
    };
  }

  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:?\s@"]+(\.[^<>()\[\]\\.,;:?\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  isRecipientValid = recipient => {
    return (
      recipient != null &&
      recipient.first_name != null &&
      recipient.first_name.trim() != '' &&
      recipient.last_name != null &&
      recipient.last_name.trim() != '' &&
      recipient.email != null &&
      recipient.email.trim() != '' &&
      this.validateEmail(recipient.email)
    );
  };

  createEmptyRecipient = () => {
    return {
      id: null,
      first_name: '',
      last_name: '',
      description: '',
      email: '',
      locale: '',
      isDeleted: false,
      recipient_consent: false
    };
  };

  listIsValid = () => {
    const visibleRecipients = this.state.recipients.filter(
      el => el.isDeleted == false
    );

    let allValid = visibleRecipients.length > 0;

    visibleRecipients.forEach(recipient => {
      if (this.isRecipientValid(recipient) == false) {
        allValid = false;
      }
    });

    if (allValid) {
      allValid = (this.state.name || '').toString().trim().length > 0;
    }
    return allValid;
  };

  updateList = e => {
    e.preventDefault();
    if (this.listIsValid()) {
      const submitData = {
        name: this.state.name,
        recipient_list_members_attributes: this.state.recipients.map(
          (recipient, i) => {
            const personAttributes = {
              first_name: recipient.first_name,
              last_name: recipient.last_name,
              email: recipient.email,
              company: recipient.description,
              locale: recipient.locale,
            };

            const memberAttributes = {
              person_attributes: personAttributes,
              _destroy: recipient.isDeleted
            };

            if (recipient.id != null) {
              personAttributes.id = recipient.id;
              memberAttributes.person_id = recipient.id;
            }

            if (recipient.recipient_list_member_id != null) {
              memberAttributes.id = recipient.recipient_list_member_id;
            }

            return memberAttributes;
          }
        )
      };

      $.ajax({
        url: this.props.update_url,
        type: 'PUT',
        dataType: 'json',
        data: { recipient_list: submitData },
        cache: false,
        success: data => {
          const recipientList = data.recipient_list;
          this.setState(recipientList);
          if (recipientList.errors.name || recipientList.errors.recipients) {
            ('recipient_required');
          } else {
            $(
              ReactDOM.findDOMNode(this)
            ).trigger('react.recipient_list_editor.updated', [
              this.props.id,
              this.props.reload_on_success
            ]);
            this.close();
          }
        }
      });
    }
  };

  createList = e => {
    e.preventDefault();
    if (this.listIsValid()) {
      const submitData = {
        name: this.state.name,
        recipient_list_members_attributes: this.state.recipients.map(
          (recipient, i) => {
            const personAttributes = {
              first_name: recipient.first_name,
              last_name: recipient.last_name,
              email: recipient.email,
              company: recipient.description,
              locale: recipient.locale,
              _destroy: recipient.isDeleted
            };

            const memberAttributes = {
              person_attributes: personAttributes,
            };

            if (recipient.id != null) {
              personAttributes.id = recipient.id;
              memberAttributes.person_id = recipient.id;
            }

            return memberAttributes;
          }
        )
      };

      $.ajax({
        url: this.props.create_url,
        type: 'POST',
        dataType: 'json',
        data: { recipient_list: submitData },
        cache: false,
        success: data => {
          const recipientList = data.recipient_list;
          this.setState(recipientList);
          if (recipientList.errors.name || recipientList.errors.recipients) {
            ('recipient_required');
          } else {
            $(
              ReactDOM.findDOMNode(this)
            ).trigger('react.recipient_list_editor.created', [
              this.props.reload_on_success
            ]);

            this.close();
          }
        }
      });
    }
  };

  deleteList = e => {
    e.preventDefault();
    if (this.listIsValid()) {
      const submitData = {};

      $.ajax({
        url: this.props.update_url,
        type: 'DELETE',
        dataType: 'json',
        data: { recipient_list: submitData },
        cache: false,
        success: data => {
          $(
            ReactDOM.findDOMNode(this)
          ).trigger('react.recipient_list_editor.deleted', [
            this.props.id,
            this.props.reload_on_success
          ]);
          this.close();
        }
      });
    }
  };

  addRecipient = () => {
    this.state.recipients.push(this.createEmptyRecipient());
    this.setState({ recipients: this.state.recipients });
  };

  updateRecipients = (recipient, idx) => {
    /* this.state.recipients.forEach((recipient) => {
      recipient.isValid = this.isRecipientValid(recipient);
    }); */

    // this.state.recipients.splice(recipient_idx, 1);

    // this.state.recipients[idx] = recipient;
    this.setState({ recipients: this.state.recipients });
  };

  open = () => {
    this.$modalNode.modal('show');
    setTimeout(() => {
      this.$modalNode.find('#recipient_list_name').focus();
    }, 500);
  };

  close = () => {
    this.$modalNode.modal('hide');
    ReactDOM.unmountComponentAtNode(this.modalNode.parentNode);
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    $.ajax({
      url: this.props.target,
      type: 'POST',
      dataType: 'json',
      data: { recipient_list: this.state },
      cache: false,
      success: data => {
        const recipientList = data.recipient_list;
        this.setState(recipientList);
        if (recipientList.errors.name || recipientList.errors.recipients) {
          ('recipient_required');
        } else {
          this.close();
        }
      }
    });
  };

  render() {
    let nameFormGroupClass = 'form-group';
    if (this.state.errors && this.state.errors.name) {
      nameFormGroupClass += ' has-error';
    }

    let updateBtn = null;
    let createBtn = null;
    let cancelBtn = null;
    let modalTitle = null;

    if (this.props.update_url != null) {
      modalTitle = I18n.t('general.edit_list');

      cancelBtn = (
        <a
          className='btn btn-gray spacer-right-xs pull-left'
          data-dismiss='modal'
        >
          {I18n.t('general.cancel')}
        </a>
      );

      updateBtn = (
        <a
          className='btn btn-main-pronotif'
          onClick={this.updateList}
          data-disable-with={
            `${I18n.t('form.save')  } <i class='fa fa-spinner fa-spin'></i>`
          }
          disabled={!this.listIsValid() || this.emailIsValid}
        >
          {I18n.t('form.save')}
        </a>
      );
    }

    if (this.props.create_url != null) {
      modalTitle = I18n.t('general.new_list');

      cancelBtn = (
        <a
          className='btn btn-gray spacer-right-xs pull-left'
          data-dismiss='modal'
        >
          {I18n.t('general.cancel')}
        </a>
      );

      createBtn = (
        <a
          className='btn btn-main-pronotif'
          onClick={this.createList}
          data-disable-with={
            `${I18n.t('form.save')  } <i class='fa fa-spinner fa-spin'></i>`
          }
          disabled={!this.listIsValid() || this.emailIsValid}
        >
          {I18n.t('form.save')}
        </a>
      );
    }

    // let recipientRows = null;
    const recipientRows = this.state.recipients.map((recipient, i) => {
      if (!recipient.isDeleted) {
        return (
          <RecipientListEditorRow
            key={i}
            legal_description_enabled={this.props.legal_description_enabled}
            recipient={recipient}
            recipient_idx={i}
            recipient_updated={this.updateRecipients}
            person_verification_url={this.props.person_verification_url}
          />
        );
      }
      return null;
    });

    let invalidEmailMsg = null;
    if (this.state.undeliverables.length > 0) {
      invalidEmailMsg = (
        <div className='spacer-bottom-xs'>
          <span className='text-red text-medium'>
            {I18n.t('general.x_list_edit_modal_warning', {
              count: this.state.undeliverables.length
            })}
          </span>
        </div>
      );
    }

    return (
      <div
        className='modal fade'
        tabIndex='-1'
        ref={ref => {
          (this.modalNode = ref), (this.$modalNode = $(ref));
        }}
      >
        <form className='form'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>
                <h4 className='modal-title text-medium text-md'>
                  {modalTitle}
                </h4>
              </div>
              <div className='modal-body'>
                {invalidEmailMsg}

                <div className={nameFormGroupClass}>
                  <label htmlFor='recipient_list_name'>
                    {I18n.t('form.list_name')}
                  </label>
                  <input
                    onChange={this.handleNameChange}
                    autoFocus='autofocus'
                    className='form-control'
                    type='text'
                    id='recipient_list_name'
                    value={this.state.name}
                  />
                </div>

                <div className='row spacer-top-xs'>
                  <div className='col-xs-12'>
                    <span className='font-size-16 text-medium'>
                      {I18n.t('general.members')}
                    </span>
                  </div>
                </div>

                {recipientRows}

                <div className='row spacer-top-xs'>
                  <div className='col-sm-12 spacer-top-xxs'>
                    <div className='text-center'>
                      <a
                        className='box box-sm box-highlighted block'
                        onClick={this.addRecipient}
                      >
                        {I18n.t('general.add_member')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                {cancelBtn}
                {createBtn}
                {updateBtn}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

class RecipientListEditorRow extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.recipient_updated)
      console.error('No `recipient_updated` property found.');
    if (!this.props.recipient) console.error('No `recipient` property found.');
    if (this.props.recipient_idx == null)
      console.error('No `recipient_idx` property found.');

    const {recipient} = this.props;

    if (recipient.consent == null) {
      recipient.consent = false;
    }

    if (recipient.locale == '') {
      recipient.locale = 'en';
    }

    if (recipient.id)
      this.debouncedPersonVerification(recipient);

    this.state = {
      recipient,
      hideEmailMalformedNotice: true,
      originalLocale: recipient.locale
    };
  }

  async personVerification(recipient) {
    await $.ajax({
      url: this.props.person_verification_url,
      type: 'GET',
      dataType: 'json',
      data: { person: recipient },
      cache: false,
      success: result => {
        if (result?.person) {
          this.state.recipient.id = result.person.id;
          this.handleRecipientUpdated();
          this.setState({ originalLocale: result.person.locale });
        } else {
          delete this.state.recipient['id']
          this.handleRecipientUpdated();
          this.setState({ originalLocale: recipient.locale });
        }
      }
    });
  }

  debouncedPersonVerification = debounce(this.personVerification, 250);

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:?\s@"]+(\.[^<>()\[\]\\.,;:?\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  emailIsValid(email) {
    if (email == null)
      email = this.state.recipient.email

    return (email == null || email.trim() == '' || this.validateEmail(email))
  }

  nameIsValid(name) {
    return (name == null || name.trim() == '' || this.validateName(name))
  }

  handleRecipientUpdatedWithVerification() {
    this.handleRecipientUpdated();
    if (this.state.recipient.first_name && this.state.recipient.last_name && this.state.recipient.email) {
      this.debouncedPersonVerification(this.state.recipient);
    }
  }

  handleRecipientUpdated() {
    this.props.recipient_updated(
      this.state.recipient,
      this.props.recipient_idx
    );
  }

  handleEmailChange = e => {
    this.state.recipient.email = e.target.value;
    // this.state.recipient.isValid = this.isRecipientValid(this.state.recipient);
    // this.setState({recipient: this.state.recipient});

    this.state.hideEmailMalformedNotice = this.emailIsValid(this.state.recipient.email)

    this.handleRecipientUpdatedWithVerification();
  };

  handleFirstNameChange = e => {
    this.state.recipient.first_name = e.target.value;

    this.handleRecipientUpdatedWithVerification();
  };

  handleLastNameChange = e => {
    this.state.recipient.last_name = e.target.value;

    this.handleRecipientUpdatedWithVerification();
  };

  handleDescriptionChange = e => {
    this.state.recipient.description = e.target.value;
    // this.state.recipient.isValid = this.isRecipientValid(this.state.description);
    // this.setState({recipient: this.state.recipient});
    this.handleRecipientUpdatedWithVerification();
  };

  handleLocaleChange = e => {
    this.state.recipient.locale = e.target.value;
    this.handleRecipientUpdatedWithVerification();
  };

  deleteRecipient = () => {
    this.state.recipient.isDeleted = true;
    this.handleRecipientUpdatedWithVerification();
  };

  tagName(attrName) {
    return `recipient_${  attrName  }_${  this.props.recipient_idx}`;
  }

  recipientDescriptionBlock() {
    if (this.props.legal_description_enabled) {
      return (
        <div className='col-xs-12 col-sm-10'>
          <div className='form-group'>
            <label htmlFor={this.tagName('description')}>
              {I18n.t('recipients.legal_description')}
            </label>
            <input
              className='form-control'
              type='text'
              onChange={this.handleDescriptionChange}
              value={this.state.recipient.description || ''}
              id={this.tagName('description')}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    const locales = [
      [I18n.t('general.locale_en'), 'en'],
      [I18n.t('general.locale_fr'), 'fr'],
      [
        `${I18n.t('general.locale_fr')  } / ${  I18n.t('general.locale_en')}`,
        'fr_en'
      ]
    ];

    return (
      <div
        className={`spacer-top-xxs spacer-bottom-xs ${
          this.state.recipient.deliverable === false
            ? 'list-editor-item-box-error'
            : 'list-editor-item-box'
        }`}
      >
        <div className='row spacer-top-xxs'>
          <div className='col-sm-5'>
            <div className='form-group'>
              <label htmlFor={this.tagName('first_name')}>
                {I18n.t('recipients.first_name')}
              </label>
              <input
                className='form-control'
                type='text'
                onChange={this.handleFirstNameChange}
                value={this.state.recipient.first_name}
                id={this.tagName('first_name')}
              />
            </div>
          </div>

          <div className='col-sm-5'>
            <div className='form-group'>
              <label htmlFor={this.tagName('last_name')}>
                {I18n.t('recipients.last_name')}
              </label>
              <input
                className='form-control'
                type='text'
                onChange={this.handleLastNameChange}
                value={this.state.recipient.last_name}
                id={this.tagName('last_name')}
              />
            </div>
          </div>

          <div className='col-xs-3 col-sm-2 text-right'>
            <div className='form-group'>
              <div className='form-control form-control-invisible'>
                <a className='remove-item' onClick={this.deleteRecipient}>
                  <i className='fa fa-trash' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <div className='form-group'>
              <label htmlFor={this.tagName('email')}>
                {I18n.t('recipients.email')}
              </label>
              <input
                className={`form-control ${
                  this.state.recipient.deliverable === false
                    ? 'error-field'
                    : ''
                }`}
                type='text'
                onChange={this.handleEmailChange}
                value={this.state.recipient.email}
                id={this.tagName('email')}
              />
              <p
                className='small text-red'
                hidden={this.state.hideEmailMalformedNotice}
              >
                {I18n.t('recipients.malformed_email')}
              </p>
            </div>
          </div>
          <div className='col-sm-5'>
            <div className='form-group'>
              <div className='recipient-list-locale-div'>
                <label htmlFor={this.tagName('locale')}>
                  {I18n.t('general.language')}
                </label>
                <select
                  className='form-control'
                  onChange={this.handleLocaleChange}
                  value={this.state.recipient.locale || 'en'}
                  id={this.tagName('locale')}
                >
                  {locales.map((locale, i) => (
                    <option key={i} value={locale[1]}>
                      {locale[0]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {this.recipientDescriptionBlock()}
        </div>
        <p
          className='small text-red'
          hidden={this.state.originalLocale === this.state.recipient.locale}
        >
          {I18n.t('recipients.person_exists_with_different_locale', { recipient_locale: locales.find((elem) => { return elem[1] == this.state.originalLocale })[0]})}
        </p>
      </div>
    );
  }
}

export default RecipientListEditor;
