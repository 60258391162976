import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchFilter extends Component {

  static propTypes = { 
    onSearchChange: PropTypes.func.isRequired
  };

  render() {
    return (     
      <React.Fragment>
        <div className="btn-group">
          <div className="search-input-group">
            <div className="input-group">
              <span className="input-group-addon">
                <div className="btn btn-option btn-search-option"><i className="fa fa-search"></i></div>
              </span>
              <input type="search" onChange={this.props.onSearchChange} placeholder={I18n.t("general.search")} className="form-control form-control-search expanded" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )     
  } 
}  

export default SearchFilter;
