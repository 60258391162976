import React, { Component } from 'react';

class ModalActivityNoConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  open = () => {
    this.$modalNode.modal('show');
  };

  redirectTo = (url) => {
   window.location.href = url;
  }

  render() {
    const handleChange = () => {
      this.setState({ checked: !this.state.checked })
    };

    return (
      <div
        className='modal fade'
        tabIndex='-1'
        ref={ref => {
        (this.modalNode = ref), (this.$modalNode = $(ref));
      }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header' style={{ display: 'flex', 'flexDirection': 'row' }}>
              <h5 className='modal-title'>
                {I18n.t('activities.no_consent_modal.title')}
              </h5>
              <span style={{ 'flexGrow': 1 }} />
            </div>
            <div className='modal-body'>
              <div className='stars-block d-flex justify-content-center align-items-center mb-5'>
                <p>{I18n.t('activities.no_consent_modal.body')}</p>
              </div>
              <div className='stars-block d-flex align-items-center mb-5'>
                <label className='d-flex justify-content-center align-items-center'>
                  <input
                    style={{ margin: 0 }}
                    type='checkbox'
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: '5px' }}>{I18n.t('activities.no_consent_modal.checkbox')}</span>
                </label>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' data-dismiss='modal' onClick={() => this.redirectTo('/tenants')} className='btn btn-main-pronotif'>{I18n.t('activities.no_consent_modal.cta_update')}</button>
              <button type='button' data-dismiss='modal' disabled={!this.state.checked} className='btn btn-main-pronotif'>{I18n.t('activities.no_consent_modal.cta_continue')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalActivityNoConsent
