$(document).on("turbolinks:load", function () {
  $(document).on('submit', 'form#new_user', function(e) {
    // Format the phone number as e164 before submitting
    var e164_phone_1_number = $("#user_phone_1_number").intlTelInput("getNumber");
    
    $("#user_phone_1_number").val(e164_phone_1_number);
  });

  function updateRules(container, password) {
    container.show();

    var ruleMatch = $("#password-rule-match");
    var ruleLower = $("#password-rule-lower");
    var ruleUpper = $("#password-rule-upper");
    var ruleNumber = $("#password-rule-number");
    var ruleSpecial = $("#password-rule-special");
    var ruleLength = $("#password-rule-length");

    if (ruleMatch)    ruleMatch.toggleClass("password-rule-valid",   $("#user_password").val() == $("#user_password_confirmation").val());
    if (ruleLower)    ruleLower.toggleClass("password-rule-valid",   password.match(/[a-z]/) != null);
    if (ruleUpper)    ruleUpper.toggleClass("password-rule-valid",   password.match(/[A-Z]/) != null);
    if (ruleNumber)   ruleNumber.toggleClass("password-rule-valid",  password.match(/[0-9]/) != null);
    if (ruleSpecial)  ruleSpecial.toggleClass("password-rule-valid", password.match(/[_\W]/) != null);
    if (ruleLength)   ruleLength.toggleClass("password-rule-valid",  password.length >= 8);
  }

  $(".js-profession").on("change", function() {
    if ($(this).val() == "realestate" || $(".js-corpiq").data("always-visible") == true) {
      $(".js-corpiq").show();
      $(".no-js-corpiq").hide();
    } else {
      $(".no-js-corpiq").show();
      $(".js-corpiq").hide();
      $(".js-corpiq").find("input[type='checkbox']").attr("checked", false);
      $(".js-corpiq").find("input[type='text']").val("");
      $(".js-corpiq").find(".js-corpiq-card-number").hide();
    }
  });

  $(".js-profession").on("change", function() {
    if ($(this).val() == "legal" || $(".js-corpiq").data("always-visible") == true) {
      $(".js-legal").show();
    } else {
      $(".js-legal").hide();
    }
  });

  $(document).on("keyup", "#user_password, #user_password_confirmation", function() {
    var passwordRulesContainer = $("#password-rules-container");
    if (passwordRulesContainer.length > 0) {
      updateRules(passwordRulesContainer, $("#user_password").val());
    }
  });

});

$(document).on('click', 'input#check_all', function () {
  
  let checkboxes = $(`.${  $(this).attr("data-target")}`);
  for (let checkbox of checkboxes) {
    $(checkbox).prop('checked', $(this).prop('checked') );
    $(checkbox.dataset.target).val($(this).prop('checked') ? (new Date).toISOString() : "");
  }
});

$(document).on('change', 'input.checkbox-toggle', function () {
  if ($(this).prop('checked')) {
    $($(this).attr("data-target")).val((new Date).toISOString());
  }else{
    $($(this).attr("data-target")).val("");
  }
});

