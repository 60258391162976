import React, { Component } from 'react';
import moment from 'moment';
import feedbackImage from '../../images/popup_feedback.png';
import '../../stylesheets/modal.css';

class ModalReview extends Component {
  open = () => {
    this.$modalNode.modal('show');
  };

  dismissForThreeMonths = e => {
    e.preventDefault();

    const today = moment();
    const inThreeMonths = moment(today).add(3, 'months');

    $.ajax({
      url: this.props.update_url,
      type: 'PUT',
      dataType: 'json',
      data: { user: { ask_review_at: inThreeMonths.toISOString() } },
      cache: false,
    });
  };

  dismissForever = e => {
    e.preventDefault();

    $.ajax({
      url: this.props.update_url,
      type: 'PUT',
      dataType: 'json',
      data: { user: { reviewed: true } },
      cache: false,
    });

    window.open('https://g.page/r/CWpGPCZYhYbZEB0/review');
  }

  render() {
    return (
      <div
        className='modal fade'
        tabIndex='-1'
        ref={ref => {
          this.modalNode = ref;
          this.$modalNode = $(ref);
        }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header' style={{ display: 'flex', flexDirection: 'row' }}>
              <h5 className='modal-title'>{I18n.t('general.title_review')}</h5>
              <span style={{ flexGrow: 1 }} />
              <button
                type='button'
                className='close p-4'
                data-dismiss='modal'
                onClick={this.dismissForThreeMonths}
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body pb-0'>
              <div className='stars-block mb-5'>
                <a href="https://g.page/r/CWpGPCZYhYbZEB0/review">
                  <img
                    src={feedbackImage}
                    alt='Description'
                    className='image-resize'
                    data-dismiss='modal'
                    onClick={this.dismissForever}
                  />
                </a>
              </div>
              <p>{I18n.t('general.body_review')}</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                data-dismiss='modal'
                onClick={this.dismissForever}
                className='btn btn-main-pronotif'
              >
                {I18n.t('general.cta_review')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalReview;
