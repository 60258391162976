import * as PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledComponent = styled.div`
    padding: 0 15px 20px 15px;
`

const SearchHeader = ({onChange}) => <StyledComponent
  className="btn-group search-input-group"
>
  <div className="input-group">
    <span className="input-group-addon">
      <div className="btn btn-option btn-search-option"><i className="fa fa-search"></i></div>
    </span>
    <input
      type="search"
      onChange={onChange}
      placeholder={I18n.t("general.search")}
      className="form-control form-control-search expanded"
    />
  </div>
</StyledComponent>

SearchHeader.propTypes = {onChange: PropTypes.func.isRequired}

export default SearchHeader