import React from 'react'
import {ConfigProvider, Space} from 'antd';
import PropTypes from 'prop-types'
import DashboardAlert from './DashboardAlert';

export function DashboardAlerts(props) {
  const {alerts} = props;

  const generateAlerts = () => {
    return alerts.filter(x => x).map((alert) => {
      return(<DashboardAlert
        key={alert.activityType+alert.status}
        activityType={alert.activityType}
        errorType={alert.errorType}
        numberOfErrors={alert.count}
        linkTo={alert.link}
        status={alert.status}
      />)
    })
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Roboto-Light", "Roboto Light", "Roboto", sans-serif;',
          fontSize: 13
        },
      }}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        {generateAlerts()}
      </Space>
    </ConfigProvider>
  )
}
DashboardAlerts.propTypes = {
  alerts: PropTypes.array.isRequired,
}
export default DashboardAlerts;