import React  from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  
`

const Conversation = props =>
  <Container>
    {messages.each(message => {

    })}
  </Container>

export default Conversation

