import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import update from 'immutability-helper';
import axios from 'axios';

import { DataScroller } from "primereact/components/datascroller/DataScroller";
import { BeatLoader } from 'react-spinners';

export class LogsList extends Component {

  static defaultProps = {
    loaderColor: '#0056ba'
  }

  static propTypes = {
    loaderColor: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      logs: [],
      first: 0,
      currentPage: 1,
      loading: false,
      queryParams: {
        "page[number]": 1,
        "page[size]": 25
      }
    };
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({ loading: true });

    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then((response) => {
          let data = response.data.data || []
          let meta = response.data.meta || {}

          this.setState({
            totalRecords: meta.pagination.total_count,
            totalPages: meta.pagination.total_pages,
            currentPage: meta.pagination.current_page,
            nextPage: meta.pagination.next_page,
            loading: false
          });

          this.setState(
            update(this.state, { logs: { $push: data } })
          );
        })
        .catch((error) => {
          console.log("Error loading data for datagrid", error);
        })
        .finally(function () {
          this.setState({ loading: false });
        }.bind(this))

    return promise;
  }

  hasMoreData = () => {
    return (this.state.nextPage) ? true : false
  }

  onLazyLoad = (event) => {
    this.setState({
      first: event.first,
      queryParams: update(this.state.queryParams, { "page[number]": { $set: this.state.nextPage } })
    })

    if (this.props.dataSourceUrl) {
      this.loadData(this.props.dataSourceUrl, this.state.queryParams).then(() => {
        this.setState({
          rows: this.state.logs.length
        })
      })
    }
  }

  logTemplate = (log) => {
    return (
      <div className="p-grid">
        <div className="row">
          <div className="col-xs-2">
            <div className="text-center font-size-18" style={{ minWidth: '21px', maxWidth: '21px' }}>
              <i className={log.icon_class}></i>
            </div>
          </div>
          <div className="col-xs-6">
            <span>{log.message}</span>
          </div>
          <div className="col-xs-4 text-gray text-right">
            <span>{log.created_at_formatted}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let footer = (
      <button className="btn btn-main-pronotif" ref={(el) => this.loadButton = el} className={classNames('btn btn-main-pronotif text-regular', { 'hidden': !this.hasMoreData() })}>
        {I18n.t("general.load_more")}
      </button>
    );

    return (
      <React.Fragment>
        <DataScroller className={classNames({ 'hidden': this.state.loading })} value={this.state.logs} itemTemplate={this.logTemplate} rows={this.state.rows} lazy={true} onLazyLoad={this.onLazyLoad} loader={this.loadButton} footer={footer} />
        <BeatLoader
          css={{ display: 'block', margin: 'auto', padding: '50px 0', textAlign: 'center' }}
          sizeUnit={"px"}
          size={15}
          color={this.props.loaderColor}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

export default LogsList;
