var timer

// Misc

// For when we have buttons in clickable rows, to prevent row link from submitting
$(document).on('click', '.js-prevent-click-event', function (e) {
  e.stopPropagation()
})

$(document).on('change keyup', '.js-form-submit', function () {
  if (timer) {
    clearTimeout(timer)
  }

  var that = this
  timer = setTimeout(function () {
    if ($(that).val().length > 0) {
      $(that).parents('form').submit()
    }
  }, 800)
})

$(document).on('ready', function () {
  //if (
  //  $(this)
  //    .find('input#q.form-control:first')
  //    .val()
  //) {
  //  $(this)
  //    .find('i.fa-search')
  //    .hide();
  //}

  // Init Document Name Header
  if ($('.panes .left-pane .js-doc').length) {
    var name = $('.js-email-document-name').first().text()
    $('.js-email-document-name-header').text(name)
  }
})

// $(document).on('ready turbolinks:load', function() {
//   if (
//     $(this)
//       .find('.form-control-search')
//       .val()
//   ) {
//     $('.form-control-search').toggleClass('expanded');
//   }
// });

// $(document).on('ready', function() {
//   if (
//     $(this)
//       .find('.form-control-search')
//       .val()
//   ) {
//     $('.form-control-search').toggleClass('expanded');
//   }
// });

// $(document).on('click', '.btn-search-option', function(e) {
//   $('.form-control-search').toggleClass('expanded');
// });

$(document).on('click', '.panes .left-pane .js-doc', function (e) {
  $('.panes .left-pane .js-doc').removeClass('active')
  $(this).toggleClass('active')

  var name = $(this).find('.js-email-document-name').text()
  $('.js-email-document-name-header').text(name)
})

$(document).on('click', '.panes .left-pane .js-time', function (e) {
  $('.panes .left-pane .js-time').removeClass('active')
  $(this).toggleClass('active')

  var url = $(this).data('form-action')
  $('#new_reply').attr('action', url)
})

$(document).on('keyup', '.js-form-control-clear', function (e) {
  var $input = $(this).find('input.form-control:first')
  $(this).find('i.fa-search').toggle(Boolean(!$input.val()))
  $(this).find('.js-form-control-clearer').toggle(Boolean($input.val()))
})

$(document).on('click', '.js-form-control-clearer', function (e) {
  e.preventDefault()
  $(this).prevAll('input.form-control:first').val('').focus()
  $(this).parents('form').submit()
})

$(document).on('click', '.js-clickable-row', function (event) {
  if ($(event.target).is('input[type="checkbox"], label')) {
    return
  }

  if ($(this).data('href') != null && $(this).data('href').length > 0) {
    if ($(this).data('target') != null && $(this).data('target') == '_blank') {
      window.open($(this).data('href'), '_blank')
    } else window.location = $(this).data('href')
  }
})

$(document).on('click', '.js-clickable-row.js-recipient-list-editor', function (e) {
  e.preventDefault()
  $(this).trigger('open-recipient-editor')
})

$(document).on('click', '.new-recipient-list.js-recipient-list-editor', function (e) {
  e.preventDefault()
  $(this).trigger('open-recipient-editor')
})

$(document).on('click', '.new-tenant.js-tenant-editor', function (e) {
  e.preventDefault()
  $(this).trigger('open-tenant-editor')
})

$(document).on('click', '.js-refresh', function (e) {
  Turbolinks.enableTransitionCache(true)
  Turbolinks.visit(location.toString())
  Turbolinks.enableTransitionCache(false)
  e.preventDefault()
})

// File upload disable import btn is no file is selected
$(document).on('change', '.btn-file > input:file', function (e) {
  $(this).parent().next('.submit-file-btn').prop('disabled', !$(this).val())
})

// Togglers

$(document).on('click', '[data-show]', function () {
  $($(this).data('show')).show()
})

$(document).on('click', '[data-hide]', function () {
  $($(this).data('hide')).hide()
})

$(document).on('click', '[data-toggle-content]', function (e) {
  if ($(e.target).closest('a').length) {
    e.preventDefault()
  }

  $element = $($(this).data('toggle-content'))
  $element.toggle()

  if ($element.find('input[type=text]')) {
    $element.find('input[type=text]').val('')
  } else if ($element.is('input:text')) {
    $element.val('')
  }
})

// Insert variables in text

function typeInTextarea(el, newText) {
  editor = el[0].editor

  if (editor) {
    editor.insertString(newText)
  } else {
    var start = el.prop('selectionStart')
    var end = el.prop('selectionEnd')
    var text = el.val()
    var before = text.substring(0, start)
    var after = text.substring(end, text.length)

    el.val(before + newText + after)
    el[0].selectionStart = el[0].selectionEnd = start + newText.length
    el.focus()
  }

  return false
}

$(document).on('click', '[data-variable]', function () {
  typeInTextarea($($(this).attr('data-textarea')), $(this).attr('data-variable'))
  return false
})

// Fix toggles positioning

$(document).on('ready turbolinks:load', function () {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
  })

  $('body')
    .popover({
      selector: '[data-toggle="popover"]',
      trigger: 'hover click',
    })
    .on('scroll', function () {
      $('[data-toggle="popover"]').popover('hide')
    })
    .on('mouseleave', function () {
      $('[data-toggle="popover"]').popover('hide')
    })
})

// File upload

$(document).on('change', '.btn-file > input[type=file]', function () {
  var fileName = $(this).val().replace('C:\\fakepath\\', '')
  $(this).parents().find('.js-filename').html(fileName)
})

$(document).on('click', '.navbar-notifications > ul > li', function (e) {
  if ($(e.target).is('a')) return
  e.stopPropagation()
  $(this).find('a').get(0).click()
})

// Submit form once (prevent double click)

$(document).on('submit', "form:not([data-remote='true'])", function (e) {
  if ($(this).hasClass('js-form-submitted')) {
    e.preventDefault()
    return
  }

  $(this).addClass('js-form-submitted')
})

$(document).on('ready turbolinks:load', function () {
  $('.js-display-nested-field-default').each(function () {
    if ($(this).find('.nested-fields > .row').length == 0) {
      $(this).find('.add_fields').trigger('click')
    }
  })

  $(document).on('click', '.sidebar-activity-sub-nav-items li > a', function (e) {
    $('.sidebar-activity-sub-nav').addClass('hidden')
  })
})

// For child links in parent links
$(document).on('click', '.link-stop-propagation', function (e) {
  e.stopImmediatePropagation()
})
