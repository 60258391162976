import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

class PropertyListRow extends React.Component {
  render() {
    return (
      <tr onClick={this.props.onClick}>
        <td className="text-medium">{this.props.property.name}</td>
        <td>{this.props.property.street_address}</td>
        <td>{this.props.property.city}</td>
        <td className="hidden">{this.props.property.postal_code_subdivision_code}</td>
        <td className="text-center">{this.props.property.tenants_count}</td>
      </tr>
    );
  }
}

class PropertyList extends React.Component {
  static propTypes = {
    dataSourceUrl: PropTypes.string.isRequired,
    showAllTenants: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      properties: [],
      inputValue: ""
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.dataSourceUrl) {
      this.loadData(this.props.dataSourceUrl);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({ loading: true });

    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then(function (response) {
          this.setState({
            properties: response.data.properties || [],
          });
        }.bind(this))
        .catch(function (error) {
          console.log("Error loading data for properties", error);
        })
        .finally(function () {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }.bind(this));

    return promise;
  }

  updateInput = (e) => {
    this.setState({
      inputValue: e.target.value
    });
  }

  render() {
    var totalPropertyCount = this.state.properties.length;
    let rows;

    if (totalPropertyCount > 0) {
      rows = this.state.properties.map((property) => {
        return (
          <PropertyListRow
            property={property}
            key={property.id}
            onClick={this.props.onSelect.bind(this, property)}
          />
        );
      });
    } else {
      rows = (
        <tr>
          <td colSpan="5" className="text-center text-gray">
            {I18n.t("blank_state.properties.title")}
          </td>
        </tr>
      );
    }

    return (
      <div className={classNames({ "hidden": !this.props.show })}>

        <div className="row">
          <div className="col-xs-6">
            <div className="btn-group">
              <div className="search-input-group">
                <div className="input-group">
                  <input type="text" name="q" id="q" onChange={e => this.updateInput(e)} placeholder="Recherche" className="form-control form-control-search expanded modal"/>
                  <span className="input-group-addon">
                    <div className="btn btn-option btn-search-option btn-modal" onClick={(e)=> this.props.search(this.state.inputValue, e)}><a href="#" className="font-size-14 text-light"><i className="fa fa-search"></i></a></div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <h5><a href="#" className="font-size-14 text-light" onClick={this.props.showAllTenants}>{I18n.t("properties.view_all_tenants")}</a></h5>
          </div>
          <div className="col-xs-6 text-right">
            <h5 className="font-size-16 text-regular">({I18n.t("general.property_count", { count: totalPropertyCount })})</h5>
          </div>
        </div>

        <div className={classNames({ "hidden": this.state.loading }, "table-sticky-header-container")}>
          <table className="table table-hover table-styled table-sticky-header table-select">
            <thead>
              <tr>
                <th>{I18n.t("form.name")}</th>
                <th>{I18n.t("form.address")}</th>
                <th>{I18n.t("form.city")}</th>
                <th className="hidden">{I18n.t("form.area")}</th>
                <th className="text-center">{I18n.t("general.tenants")}</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>

        <div className={classNames("form-overlay text-center", { "hidden": !this.state.loading })}>
          <BeatLoader
            css={{ display: 'block', margin: '0 auto' }}
            sizeUnit={"px"}
            size={15}
            color={'#0056ba'}
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

export default PropertyList;