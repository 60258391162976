import styled          from 'styled-components'

export const Container = styled.div`
  border-width: 0px;
  left: 0px;
  top: 0px;
  width: 395px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-box-shadow: -2px 0px 5px rgba(0, 21, 68, 0.117647058823529);
  -webkit-box-shadow: -2px 0px 5px rgba(0, 21, 68, 0.117647058823529);
  box-shadow: -2px 0px 5px rgba(0, 21, 68, 0.117647058823529);
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-style: normal;
  text-align: left;
`