/*
Listen for the turbolinks:before-cache event if you need to prepare the document before Turbolinks caches it.
You can use this event to reset forms, collapse expanded UI elements, or tear down any third-party widgets so the page is ready to be displayed again.
*/
$(document).on("turbolinks:before-cache", function() {
  // Hide any active modal and remove the black overlay
  $(".modal").modal("hide");
  $(".modal-backdrop").remove();

  // Remove any event listeners on modals
  $(document).off("click", "a[data-remote-modal], tr[data-remote-modal]");
})

$(document).on("turbolinks:load", function () {
  var modal_holder_selector = "#modal-holder";
  var modal_selector = ".modal:not(.hidden-on-load)";

  $(document).delegate( "a[data-remote-modal], tr[data-remote-modal]", "click", function(e) {
    e.preventDefault();

    if ($(e.target).attr('rel') == 'nofollow'){
      // click from within confirm modal
      return false;
    }

    if ($(e.target).parents('[data-confirm], [data-confirm-swal]').length > 0){
      // click from confirm link that should display a confirm modal
      return false;
    }

    var location = $(this).attr("href");
    $.get(location, function(data) {
      return $(modal_holder_selector).html(data).find(modal_selector).modal();
    });

    return false;
  });

  $(document).on("ajax:success", "form[data-remote-modal]", function(event) {
    let _doc, _status, xhr;
    [_doc, _status, xhr] = event.detail

    var url = xhr.getResponseHeader("Location");

    $(".modal-backdrop").remove();
    $(modal_holder_selector).html(xhr.response).find(modal_selector).modal();

    // If the controller responded with a location, reload the page
    if (url) {
      window.location.href = url;
      window.location.reload(true);
    }

    return false;
  });

  $(document).on("ajax:error", "form[data-remote-modal]", function(event) {
    let _doc, _status, xhr;
    [_doc, _status, xhr] = event.detail;

    $(".modal-backdrop").remove();
    $(modal_holder_selector).html(xhr.response).find(modal_selector).modal();
  });

  $(document).on("shown.bs.modal", ".modal", function() {
    window.initAutocomplete()
    $(this).find("[autofocus]").focus();

    // Disable Bootstrap forcing focus on opened modal whenever it loses focus.
    // This fix typing in text box when 2 levels of modals is opened i.e: Code Editing in TinyMCE
    $(document).off('focusin.modal');

    /**
     * Override bootstrap's click behavior because it closes all modals at once.
     */
    $(this).find('.close').on('click', (e) => {
      $(this).closest('.modal').modal('hide');
      $(".modal-backdrop").remove();
      e.preventDefault();
    })
  });

  $(document).on('click', '.id-model', function() {
    const inputValue = $(this).data("value");
    navigator.clipboard.writeText(inputValue)
        .then(() => {})
        .catch(err => {});
  });
});
