import React from 'react'
import {Alert, Button} from 'antd';
import PropTypes from 'prop-types'

export function DashboardAlert(props) {
  const {numberOfErrors, errorType, activityType, linkTo, status} = props;

  const youHave = I18n.t('dashboard_alerts.message.you_have')
  const elements = I18n.t(`dashboard_alerts.message.x_${activityType}`, {count: numberOfErrors})
  const withProblem = I18n.t(`dashboard_alerts.message.with_incomplete_${status}`)

  return (
    <Alert
      message={<span>
        {youHave}
        {' '}
        <b>{elements}</b>
        {' '}
        {withProblem}
      </span>}
      type={errorType}
      showIcon
      action={
        <Button href={linkTo} className='antd-button-override'>
          {I18n.t('dashboard_alerts.details')}
        </Button>
        }
    />
  )
}

DashboardAlert.propTypes = {
  activityType: PropTypes.string.isRequired,
  errorType: PropTypes.string.isRequired,
  numberOfErrors: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  status:PropTypes.string.isRequired
}
export default DashboardAlert;
