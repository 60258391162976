import React, {Component} from "react"
import PropTypes from "prop-types"
import Calendar from "./Calendar"

class DatePicker extends Component {

  static propTypes = {
    inputClassName:    PropTypes.string,
    inputId:           PropTypes.string,
    maxDate:           PropTypes.object,
    minDate:           PropTypes.object,
    onChange:          PropTypes.func,
    placeholder:       PropTypes.string,
    readonlyInput:     PropTypes.bool,
    disabled:          PropTypes.bool,
    selectOtherMonths: PropTypes.bool,
    showIcon:          PropTypes.bool,
    style:             PropTypes.object,
    value:             PropTypes.any
  }

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
      disabled: this.props.disabled
    }
  }

  onChange = event => {
    this.setState({
                    value: event.value
                  })

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  render() {
    const dateFormat = I18n.locale === "fr" ? "d MM yy" : "MM d, yy"

    var localeObj = {
      firstDayOfWeek:  1,
      dayNames:        [I18n.t("calendar.weekdays.sun"), I18n.t("calendar.weekdays.mon"), I18n.t("calendar.weekdays.tue"), I18n.t("calendar.weekdays.wed"), I18n.t("calendar.weekdays.thu"), I18n.t("calendar.weekdays.fri"), I18n.t("calendar.weekdays.sat")],
      dayNamesShort:   [I18n.t("calendar.weekdays_short.sun"), I18n.t("calendar.weekdays_short.mon"), I18n.t("calendar.weekdays_short.tue"), I18n.t("calendar.weekdays_short.wed"), I18n.t("calendar.weekdays_short.thu"), I18n.t("calendar.weekdays_short.fri"), I18n.t("calendar.weekdays_short.sat")],
      dayNamesMin:     [I18n.t("calendar.weekdays_min.sun"), I18n.t("calendar.weekdays_min.mon"), I18n.t("calendar.weekdays_min.tue"), I18n.t("calendar.weekdays_min.wed"), I18n.t("calendar.weekdays_min.thu"), I18n.t("calendar.weekdays_min.fri"), I18n.t("calendar.weekdays_min.sat")],
      monthNames:      [I18n.t("calendar.months.jan"), I18n.t("calendar.months.feb"), I18n.t("calendar.months.mar"), I18n.t("calendar.months.apr"), I18n.t("calendar.months.may"), I18n.t("calendar.months.jun"), I18n.t("calendar.months.jul"), I18n.t("calendar.months.aug"), I18n.t("calendar.months.sep"), I18n.t("calendar.months.oct"), I18n.t("calendar.months.nov"), I18n.t("calendar.months.dec")],
      monthNamesShort: [I18n.t("calendar.months_short.jan"), I18n.t("calendar.months_short.feb"), I18n.t("calendar.months_short.mar"), I18n.t("calendar.months_short.apr"), I18n.t("calendar.months_short.may"), I18n.t("calendar.months_short.jun"), I18n.t("calendar.months_short.jul"), I18n.t("calendar.months_short.aug"), I18n.t("calendar.months_short.sep"), I18n.t("calendar.months_short.oct"), I18n.t("calendar.months_short.nov"), I18n.t("calendar.months_short.dec")],
      today: I18n.t("calendar.today"),
      clear: I18n.t("calendar.clear")
    }

    return (
      <Calendar
        {...this.props}
        dateFormat={dateFormat}
        locale={localeObj}
        onChange={this.onChange}
        selectOtherMonths={true}
        showTime={false}
        value={this.state.value}
        disabled={this.state.disabled}
      >
      </Calendar>
    )
  }

}

export default DatePicker