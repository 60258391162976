$(document).on('submit', 'form.edit-account', function(e) {
  // Format the phone number as e164 before submitting
  var e164_phone_1_number = $("#user_phone_1_number").intlTelInput("getNumber");
  var e164_phone_2_number = $("#user_phone_2_number").intlTelInput("getNumber");

  $("#user_phone_1_number").val(e164_phone_1_number);
  $("#user_phone_2_number").val(e164_phone_2_number);
});

$(document).on("change", ".active-checkbox", function(e) {
  var $this = $(this);
  var setActive = false;

  if ($this.is(':checked')) {
    setActive = true
  } else {
    setActive = false
  };

  $.ajax({
    method: "PATCH",
    url: $this.data("url"),
    data: { active: setActive }
  });
});

$(document).on("change", ".js-country-select", function() {
  const $country_select = $(this);
  const $subdivision_select = $(".js-subdivision-select");

  // check if country selection is empty
  if ($country_select.val() == '') {
    // reset the subdivisions
    $subdivision_select.find("option:gt(0)").remove();
    return;
  }

  // Disable select while loading
  $subdivision_select.attr("disabled", "disabled");

  $.ajax({
    method: "GET",
    url: $(this).data("url"),
    data: { country_code: $country_select.val() },
    dataType: 'json',
    cache: false
  })
  .done(function(data) {
    // Clear all <option> except blank state
    $subdivision_select.find("option:gt(0)").remove();
    $.each(data, function(i, value) {
      $subdivision_select.append($("<option>").text(value[0]).attr("value", value[1]));
    });
  })
  .always(function() {
    $subdivision_select.removeAttr("disabled");
  });
});

// Alert settings
$(document).on('click', 'input.send-email-alerts', function() {
  if ($(this).is(':checked')) {
    $('#granular-alert-settings-container').removeClass('hidden');
    $("#granular-alert-settings-container").find("input[type='checkbox']").prop('checked', true);
  } else {
    $("#granular-alert-settings-container").find("input[type='checkbox']").prop('checked', false);
    $('#granular-alert-settings-container').addClass('hidden');
  }
});

// Account cancellation
$(document).on("click", "#reason-option", function(e) {
  e.preventDefault();
  $form = $(this).parents("form:first");
  // If "Other" = selected, show textbox for custom reason
  if ($form.find("input#cancellation_reason_mdl").val() === I18n.t("accounts.delete_reasons.option6")) {
    $form.find("input#cancel_reason").prop('hidden', false).prop('readonly', false).val('').focus();
  } else {
    $form.find("input#cancel_reason").prop('hidden', true).prop('readonly', true);
  }
});

// Prevent form submit if user presses ENTER key in input
$(document).on("keypress keydown keyup", "input#cancel_reason", function(e) {
  if (e.keyCode == 13) {
    e.preventDefault();
  }
});

// Clear form on modal close
$(document).on("hidden.bs.modal", "div#account-cancellation", function(e) {
    $(this).find("input#cancellation_reason_mdl").val('');
    $(this).find("input#cancel_reason").prop('hidden', true).prop('readonly', true).val('');
});

$(document).on("click", "#delete-account-btn", function(e) {
  $form = $(this).parents("form:first");
  if ($form.find("input#cancel_reason").val().trim().length === 0) {
    e.preventDefault();
    $("#reason-missing-alert").slideToggle().delay(6000).slideToggle();
  }
});

// Request compiled activity proof report from account settings
$(document).on('turbolinks:load', function() {
  const containers = $("#account-settings-compiled-report-section, #account-settings-compiled-recipient-report-section, #account-settings-compiled-activity-report-section, #account-settings-compiled-billing-report-section");
  if (containers.length == 0)
    return;

  var locale = I18n.locale;
  var today = moment();
  var maximumDate = moment(today).add(1, 'years');

  $.each(containers, function() {
    const container = $(this);
    container.find('.datetimepicker6').datetimepicker({
      format: 'YYYY-MM-DD',
      locale: moment().locale(locale),
      ignoreReadonly: true
    });
    container.find('.datetimepicker7').datetimepicker({
      format: 'YYYY-MM-DD',
      locale: moment().locale(locale),
      maxDate: maximumDate,
      ignoreReadonly: true,
      useCurrent: true
    });

  })
});

$(document).on('click', '.request-compiled-report-btn', function(e) {
  const formContainer = $(e.target).parents('.compiled-reports-form');
  const startDateInput = formContainer.find(".datetimepicker6 > .datepicker-input");
  const endDateInput = formContainer.find("#datetimepicker7 > .datepicker-input");
  if (startDateInput.val().length === 0 || endDateInput.val().length === 0) {
    e.preventDefault();
    $('#missing-compiled-report-dates').prop('hidden', false);
  };
});

