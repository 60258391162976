import React from "react"
import ReactDOM from "react-dom"

import { ClioMattersSearch, ClioMattersList } from "./clio_doc_importer";

class ClioMatterSelector extends React.Component {

  constructor(props) {
    super(props);

    if (!this.props.clio_matters_search_url) console.error("No `clio_matters_search_url` property found.");

    this.state = {
      selected_matter: null,
      error_msg: ""
    }

  }

  componentDidMount() {
    $(document).trigger("activities.form.clio_matter_selector_mounted");
    this._search.performSeach("");
  }

  handleClioApiError = (errorMessage) => {
    this.setState({ error_msg: errorMessage })
  }

  matterListChanged = (newMatters) => {
    this._matters_list.updateMatters(newMatters);
  }

  matterSelected = (matter) => {
    this.setState({
      selected_matter: matter
    });
  }

  confirmSelection = () => {
    if (this.state.selected_matter != null) {
      $(ReactDOM.findDOMNode(this)).trigger("activities.form.clio_matter_selector.selected", [this.state.selected_matter]);
      this.close();
    }
  }

  open = () => {
    var $node = $(this.modalNode)
    $node.modal("show")
  }

  close = () => {
    var $node = $(this.modalNode)
    $node.modal("hide")
  }

  destroy = () => {
    var $node = $(this.modalNode)
    $node.remove();
  }

  render() {
    let alertLine = null;
    if (this.state.error_msg) {
      alertLine = (
        <div className="row">
          <div className="spacer-top-xxs">
            <div className="col-md-12">
              <div className="alert alert-danger fade in" role="alert">
                {this.state.error_msg}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="modal fade clio-doc-importer clio-matter-select" tabIndex="-1" role="dialog" ref={(ref) => this.modalNode = ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label={I18n.t("general.close", { defaultValue: "Close" })}><span>&times;</span></button>
              <h4 className="modal-title">
                {I18n.t("activities.sections.documents.select_from_clio_matters", { defaultValue: "Select a matter from" })}
                &nbsp;<img src="/clio.png" alt="Clio" width="80" className="clio-logo-modal" />
              </h4>
            </div>
            <div className="modal-body">
              {alertLine}
              <div className="row">
                <div className="">
                  <div className="col-sm-10 col-sm-offset-1">
                    <ClioMattersSearch ref={(search) => this._search = search} onChange={this.matterListChanged} handle_clio_api_errors={this.handleClioApiError} clio_matters_search_url={this.props.clio_matters_search_url} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <div className="clio-cases col-md-12">
                    <ClioMattersList ref={(matters_list) => this._matters_list = matters_list} clio_matters_selected={this.matterSelected} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="spacer-top-xxs">
                  <div className="col-md-offset-8 col-md-4 text-right">
                    <button type="button" className="btn btn-main-pronotif" disabled={(this.state.selected_matter == null)} onClick={this.confirmSelection.bind(this)}>
                      {I18n.t("general.select_matter", { defaultValue: "Select matter" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default ClioMatterSelector;
