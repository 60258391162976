import React            from 'react'
import styled           from 'styled-components'
import mobileBackground from './sms-preview.png'

export const Styled    = styled.div`
  background: url(${mobileBackground});
  background-size: cover;
  color: #1C2434;
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  height: 697px;
  margin: 10px 0 0 10px;
  padding: 150px 50px;
  text-align: center;
  width: 347px;
  font-weight: lighter;
`
export const Subject   = styled.p`
  font-weight: normal;
`
export const Timestamp = styled.p`
  font-size: smaller;
`

export const Message = styled.p`
  background: #F0F4F7;
  padding: 15px;
  font-size: 105%;
  margin-top: 30px;
  font-family: 'Roboto-Light', 'Roboto Light', 'Roboto', sans-serif;
  font-weight: lighter;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
`

const SmsPreview = ({subject, message, timestamp, ...props}) =>
  <Styled {...props}>
    <Subject>{subject}</Subject>
    <Timestamp>{timestamp}</Timestamp>
    <Message>{message}</Message>
  </Styled>

export default SmsPreview

