import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Editor } from '@tinymce/tinymce-react';
import { BeatLoader } from 'react-spinners';
import axios from 'axios';

class RepliesList extends Component {
  static defaultProps = {
    loaderColor: '#0056ba'
  }

  static propTypes = {
    dataSourceUrl: PropTypes.string.isRequired,
    personFullName: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    replySubject: PropTypes.string.isRequired,
    loaderColor: PropTypes.string,
    showSMSReply: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showReplyBtn: false,
      email: {
        content: "",
        showEditor: false,
      },
      sms: {
        content: "",
        showEditor: false
      },
      replies: [],
      queryParams: {
        "page[size]": 1000
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.loadData(this.props.dataSourceUrl, this.state.queryParams)
    }
  }

  loadData = (dataSourceUrl, query = {}) => {
    this.setState({ loading: true });
    let promise =
      axios.get(dataSourceUrl, { params: query })
        .then((response) => {
          let data = response.data.data || []
          let meta = response.data.meta || {}

          this.setState({
            totalRecords: meta.pagination.total_count,
            totalPages: meta.pagination.total_pages,
            replies: data,
            showReplyBtn: (data.length > 0)
          });
        })
        .catch((error) => {
          console.log("Error loading data for datagrid", error);
        })
        .finally(function () {
          this.setState({ loading: false });
          this.scrollToBottom();
        }.bind(this))
    return promise;
  }

  renderSmsOrEmail = (reply) => {
    const icon = (reply.reply_type == 'sms') ? 'fal fa-sms' : 'fal fa-envelope'

    return (
      <i className={icon} style={{ 'fontSize': '2em', 'verticalAlign': 'middle' }}></i>
    )
  }

  renderReplyFromSender = (reply) => {
    return (
      <div className="row" key={reply.id}>
        <div className="col-xs-11 col-xs-offset-1 col-md-9 col-md-offset-3 spacer-bottom-xs text-right">
          <div className="reply-box">
            { this.renderSmsOrEmail(reply) }
          </div>
          <div className="reply-box reply-sender font-size-14 text-light">
            <div dangerouslySetInnerHTML={{ __html: reply.message }}></div>
            {reply.documents.map((doc, i) =>
              <a key={doc.id} className="navy-link link-underline p-t-10 text-regular block" href={doc.file_url}><i className="fal fa-paperclip m-r-5"></i>{doc.file_name}</a>
            )
            }
          </div>
          <span className="text-gray block">{reply.created_at_formatted}</span>
        </div>
      </div>
    )
  }

  renderReplyFromRecipient = (reply) => {
    return (
      <div className="row" key={reply.id}>
        <div className="col-xs-11 col-md-9 spacer-bottom-xs text-left">
          <div className="reply-box reply-recipient font-size-14 text-light">
            <div dangerouslySetInnerHTML={{ __html: reply.message }}></div>
            {reply.documents.map((doc, i) =>
              <a key={doc.id} className="navy-link link-underline p-t-10 text-regular block" href={doc.file_url}><i className="fal fa-paperclip m-r-5"></i>{doc.file_name}</a>
            )
            }
          </div>
          <div className="reply-box">
            { this.renderSmsOrEmail(reply) }
          </div>
          <span className="text-gray block">{reply.created_at_formatted}.&nbsp;{I18n.t("general.from")}&nbsp;{this.props.personFullName}</span>
        </div>
      </div>
    )
  }

  smsEditor = {
    show: () => {
      this.setState({
        email: { ...this.state.email, showEditor: false },
        sms:   { ...this.state.sms, showEditor: true },
        showReplyBtn: false
      });
      this.scrollToBottom();
    },
    clear: () => {
      this.smsEditor.change("")
    },
    change: (content) => {
      this.setState(prevState => ({
        ...prevState,
        sms: {
          ...prevState.sms,
          content: content
        }
      }))
    },
    isEmpty: () => {
      return this.state.sms.content.length == 0;
    },
    save: () => {
      const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }

      axios.post(this.props.dataSourceUrl, {
        authenticity_token: token.content,

        sms_reply: {
          message: this.state.sms.content
        }
      }).then((response) => {
        if (response.data.sms_reply) {
          this.setState({ replies: [...this.state.replies, response.data.sms_reply] });
          this.hideEditors();
          this.scrollToBottom();
          this.smsEditor.clear();
        }
      })
    }
  }

  emailEditor = {
    show: () => {
      this.setState({
        email: { ...this.state.email, showEditor: true },
        sms:   { ...this.state.sms, showEditor: false },
        showReplyBtn: false
      });
      this.scrollToBottom();
    },
    clear: () => {
      this.emailEditor.change("")
    },
    change: (content) => {
      this.setState(prevState => ({
        ...prevState,
        email: {
          ...prevState.email,
          content: content
        }
      }))
    },
    isEmpty: () => {
      return this.state.email.content.length == 0;
    },
    save: () => {
      const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }

      axios.post(this.props.dataSourceUrl, {
        authenticity_token: token.content,
        email_reply: {
          subject: this.props.replySubject,
          message: this.state.email.content,
          to_recipient: true,
        }
      }).then((response) => {
        if (response.data.email_reply) {
          this.setState({ replies: [...this.state.replies, response.data.email_reply] });
          this.hideEditors();
          this.scrollToBottom();
          this.emailEditor.clear();
        }
      })
    },
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    })
  }

  hideEditors = () => {
    this.setState({
      email: { ...this.state.email, showEditor: false },
      sms:   { ...this.state.sms, showEditor: false },
      showReplyBtn: true
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xs-12 mh-scroll-container">
            <div>
              {
                this.state.replies.map((reply) =>
                  reply.from_recipient ? this.renderReplyFromRecipient(reply) : this.renderReplyFromSender(reply)
                )
              }

              <div style={{ float: "left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}></div>
            </div>

            <BeatLoader
              css={{ display: 'block', margin: '0 auto', textAlign: 'center' }}
              sizeUnit={"px"}
              size={15}
              color={this.props.loaderColor}
              loading={this.state.loading}
            />

          </div>
        </div>

        <div className="row">
          <div className={classNames("col-xs-12 spacer-top-xs new-reply-container", { 'hidden': !this.state.email.showEditor })}>
            <Editor
              initialValue=""
              value={this.state.email.content}
              init={{
                skin: false,
                fontsize_formats: "8px 10px 11px 12px 14px 18px 24px 36px",
                object_resizing: true,
                themes: 'modern',
                branding: false,
                statusbar: false,
                height: 160,
                menubar: false,
                force_br_newlines: false,
                force_p_newlines: false,
                forced_root_block: '',
                plugins: 'lists, placeholder, noneditable, paste',
                toolbar: 'styleselect | removeformat | fontsizeselect | bold italic underline | bullist numlist | code',
                content_css: '/css/mce.css'
              }}
              onEditorChange={this.emailEditor.change}
              textareaName="sender-reply"
            />
          </div>

          <div className={classNames("col-xs-12 spacer-top-xs new-reply-container", { 'hidden': !this.state.sms.showEditor })}>
            <Editor
              initialValue=""
              value={this.state.sms.content}
              init={{
                menubar: false,
                toolbar: 'undo redo',
                statusbar: false,
                skin: false,
                object_resizing: false,
                themes: 'modern',
                branding: false,
                height: 160,
                force_br_newlines: false,
                force_p_newlines: false,
                forced_root_block: '',
                content_css: '/css/mce.css'
              }}
              onEditorChange={this.smsEditor.change}
              textareaName="sender-reply"
            />
          </div>
        </div>

        <div className={classNames("row", { 'hidden': !this.state.email.showEditor })}>
          <div className="col-xs-6 spacer-top-xs text-left">
            <button className="btn btn-gray" onClick={this.hideEditors}>{I18n.t("general.cancel")}</button>
          </div>
          <div className="col-xs-6 spacer-top-xs text-right">
            <button className="btn btn-main-pronotif" onClick={this.emailEditor.save} disabled={this.emailEditor.isEmpty()}>{I18n.t("activities.send")}</button>
          </div>
        </div>

       <div className={classNames("row", { 'hidden': !this.state.sms.showEditor })}>
          <div className="col-xs-6 spacer-top-xs text-left">
            <button className="btn btn-gray" onClick={this.hideEditors}>{I18n.t("general.cancel")}</button>
          </div>
          <div className="col-xs-6 spacer-top-xs text-right">
            <button className="btn btn-main-pronotif" onClick={this.smsEditor.save} disabled={this.smsEditor.isEmpty()}>{I18n.t("activities.send")}</button>
          </div>
        </div>

        <div className={classNames('row', { 'hidden': !this.state.showReplyBtn })}>
          <div className="col-xs-12 spacer-top-xs text-right">
            <div className="btn-group">
              {this.props.showSMSReply ? (
                <>
                  <button type="button" className="btn btn-main-pronotif dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    { I18n.t('general.reply_to_recipient', { recipient_name: this.props.personFullName }) }
                    &nbsp;
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu">
                    <li onClick={this.emailEditor.show}><a>By Email</a></li>
                    { this.props.showSMSReply &&
                      <li onClick={this.smsEditor.show}><a>By SMS</a></li>
                    }
                  </ul>
                </>
              ) : (
                <button className="btn btn-main-pronotif" onClick={this.emailEditor.show}>
                  <i className="fal fa-reply m-r-5" />
                  { I18n.t('general.reply_to_recipient', { recipient_name: this.props.personFullName }) }
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default RepliesList;
