import React from 'react'

import ProgressBar from 'react-fine-uploader/progress-bar'
import STATUS from '../../constants/file_upload_statuses';

class ActivityUploaderFile extends React.Component {
  enableDeleteStatuses = [STATUS.PROCESSING, STATUS.UPLOADED]

  statusIcon() {
    switch (this.props.file.status) {
      case STATUS.SUBMITTED:
        return 'fine-uploader-item-status fa fa-cloud-upload'

      case STATUS.UPLOADING:
        return 'fine-uploader-item-status uploading-file fa fa-circle-o-notch fa-spin'

      case STATUS.PROCESSING:
        // return "fine-uploader-item-status fa fa-cog fa-spin"
        return 'fine-uploader-item-status fa fa-check'

      case STATUS.UPLOADED:
        return 'fine-uploader-item-status fa fa-check'

      default:
        return ''
    }
  }

  removeFile(id) {
    this.props.remove(id);
  }

  render() {
    let field = null;
    let docCollectItemIdField = null;
    let deleteField = null;
    let progressBar = null;
    const converting = null;
    let name = this.props.file.document ? this.props.file.document.file_name : this.props.file.name;
    const baseFieldName = (this.props.recipient_idx == null) ? `${this.props.type}[documents_attributes][${this.props.doc_idx}]` : `${this.props.type}[recipients_attributes][${this.props.recipient_idx}][documents_attributes][${this.props.doc_idx}]`;

    if (this.props.file.document) {

      const fieldName = `${baseFieldName  }[id]`;

      field = (
        <input type='hidden' name={fieldName} value={this.props.file.document.id} />
      );

      if (this.props.doc_collect_item_id != null) {
        const docCollectFieldName = `${baseFieldName  }[doc_collect_item_id]`;
        docCollectItemIdField = (
          <input type='hidden' name={docCollectFieldName} value={this.props.doc_collect_item_id} />
        )
      }

      // We can't download files until they are associated to activity
      if (this.props.file.document.activity_id != null && this.props.file.status == STATUS.UPLOADED) {
        name = (
          <a href={this.props.file.document.file_url} target='_blank' rel='noreferrer'>{name}</a>
        );
      }
    }

    // If file.id is a string it means it is a ruby's UUID
    // => Already uploaded
    if (typeof this.props.file.id !== 'string') {
      progressBar = (
        <ProgressBar id={this.props.file.id} uploader={this.props.uploader} />
      );
    }

    if (this.props.file.status == STATUS.REMOVED) {

      deleteField = (
        <input type='hidden' name={`${baseFieldName  }[_destroy]`} value />
      )


      return (
        <div className='hidden'>
          {field}
          {docCollectItemIdField}
          {deleteField}
        </div>
      )
    } 
      if (this.props.file.status === STATUS.PROCESSING) {
        /* converting = (
          <span> - {I18n.t("general.converting")}</span>
        ) */
      }

      return (
        <div className='fine-uploader-item'>
          {progressBar}
          {field}
          {docCollectItemIdField}
          <div className='fine-uploader-item-file'>
            <i className={this.statusIcon()} />
            {name}
            {converting}
            <a style={{display: this.enableDeleteStatuses.includes(this.props.file.status) ? 'inline' : 'none'}} className='fine-uploader-item-remove' onClick={this.removeFile.bind(this)}><i className='fa fa-trash text-danger' /></a>
          </div>
        </div>
      )
    
  }
}

export default ActivityUploaderFile;
