import React from "react"
import ReactDOM from "react-dom"

class RecipientListSaver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      recipients_attributes: $("#recipients .nested-fields").map(function() {
        var $recipientFields = $(this);
        return {
          email: $recipientFields.find(".recipient-email").val(),
          last_name: $recipientFields.find(".recipient-first-name").val(),
          first_name: $recipientFields.find(".recipient-last-name").val(),
          description: $recipientFields.find(".recipient-description").val(),
          locale: $recipientFields.find(".recipient-locale").val(),
        };
      }).get(),
    };
  }

  open() {
    this.$modalNode.modal("show")
    setTimeout(() => { this.$modalNode.find("#recipient_list_name").focus() }, 500);
  }

  close() {
    this.$modalNode.modal("hide")
    ReactDOM.unmountComponentAtNode(this.modalNode.parentNode)
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    $.ajax({
      url: this.props.target,
      type: "POST",
      dataType: "json",
      data: { recipient_list: this.state },
      cache: false,
      success: (data) => {
        var recipientList = data.recipient_list;
        this.setState(recipientList);
        if (recipientList.errors.name || recipientList.errors.recipients) {
          "recipient_required"
        } else {
          this.close()
        }
      },
    });
  }

  render() {
    var nameFormGroupClass = "form-group";
    if(this.state.errors && this.state.errors.name) {
      nameFormGroupClass += " has-error"
    }

    return (
      <div className="modal fade" tabIndex="-1" ref={(ref) => { this.modalNode = ref, this.$modalNode = $(ref) }}>
        <form onSubmit={this.handleSubmit}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
                <h4 className="modal-title">{I18n.t("general.recipient_lists")}</h4>
              </div>
              <div className="modal-body">
                <div className={nameFormGroupClass}>
                  <label className="form-label required" htmlFor="recipient_list_name">{I18n.t("form.list_name")}</label>
                  <input onChange={this.handleNameChange} autoFocus="autofocus" className="form-control form-control" type="text" id="recipient_list_name" />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">{I18n.t("form.save")}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RecipientListSaver;
