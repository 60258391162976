import React     from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Body,
  Sender,
  SentAt,
}                from './SmsBubble.styles'

export const SmsBubble = ({
  deliveryStatus,
  body = '',
  isOutgoing = true,
  senderName,
  sentAt,
}) => <Container>
  <SentAt>
    <p>{deliveryStatus} {sentAt}</p>
  </SentAt>
  <Body {...{isOutgoing}}>
    <p>{body}</p>
  </Body>
  <Sender {...{isOutgoing}}>{senderName}</Sender>
</Container>

SmsBubble.propTypes = {
  deliveryStatus: PropTypes.string,
  body:           PropTypes.string,
  isOutgoing:     PropTypes.bool,
  senderName:     PropTypes.string.isRequired,
  sentAt:         PropTypes.string.isRequired,
}

export default SmsBubble