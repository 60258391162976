import styled     from 'styled-components'
import SmsPreview from '../SmsPreview'

export const Total     = styled.span`
  color: #0C9BA8;
`
export const Summary   = styled.p`
  font-family: 'Roboto-Light', 'Roboto Light', 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  text-align: center;
`
export const Container = styled.div`
  ${SmsPreview} {
    margin-left: 200px;
  }
`