import React from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tooltip'

class TenantEditor extends React.Component {
  constructor(props) {
    super(props);

    this.locales = [
      { text: I18n.t("general.locale_en"), code: "en" },
      { text: I18n.t("general.locale_fr"), code: "fr" },
      { text: I18n.t("general.locale_fr") + " / " + I18n.t("general.locale_en"), code: "fr_en" }
    ];

    this.dirtyFields = [];
    this.state = {
      warning: true,
      first_name: this.props.first_name || '',
      last_name: this.props.last_name || '',
      email: this.props.email || '',
      phone: this.props.phone || '',
      property_id: this.props.property_id || '',
      unit: this.props.unit || '',
      description: this.props.description || '',
      locale: this.props.locale || this.locales[0]?.code,
      status: this.props.status || this.props.tenantStatuses[0][1],
      manual_consent: this.props.manual_consent || 0,
      tooltipOpen: false
    };
  }

  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:?\s@"]+(\.[^<>()\[\]\\.,;:?\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  validatePhone = phone => {
    if (!phone) return true;

    const re = /^(?=[\d-()+ ]{7,}$)[\d-()+ ]+$/;
    return re.test(phone);
  }

  isFirstNameValid = () => {
    return (
      this.state.first_name != null &&
      this.state.first_name.trim() !== ''
    );
  }

  isLastNameValid = () => {
    return (
      this.state.last_name != null &&
      this.state.last_name.trim() !== ''
    );
  }

  isEmailPresent =() => {
    return (
      this.state.email != null &&
      this.state.email.trim() !== ''
    );
  }

  isEmailValid = () => {
    return (this.validateEmail(this.state.email))
  }

  isPhoneValid = () => {
    return (
      this.validatePhone(this.state.phone)
    );
  }

  isPropertyValid = () => {
    return (
      this.state.property_id != null &&
      this.state.property_id.trim() !== ''
    );
  }

  isLocaleValid = () => {
    return (this.state.locale);
  }

  isStatusValid = () => {
    return (this.state.status);
  }

  isTenantValid = () => {
    return (
      this.isFirstNameValid() &&
      this.isLastNameValid() &&
      this.isEmailPresent() &&
      this.isEmailValid() &&
      this.isPhoneValid() &&
      this.isPropertyValid() &&
      this.isLocaleValid() &&
      this.isStatusValid()
    );
  };

  showWarning = e => {
    e.preventDefault();
    window.WarningAlert('', I18n.t('form.alert_consent'), false).then(() => { this.setState({ warning: false });});
  };

  createTenant = e => {
    e.preventDefault();
    if (this.isTenantValid()) {
      $.ajax({
        url: this.props.create_url,
        type: 'POST',
        dataType: 'json',
        data: { tenant: this.state },
        cache: false,
        success: data => {
          this.setState(data);

          $(ReactDOM.findDOMNode(this)).trigger('react.tenant_editor.created', [this.props.reload_on_success]);

          this.close();
        }
      });
    }
  };

  open = () => {
    this.$modalNode.modal('show');
    setTimeout(() => {
      this.$modalNode.find('#first_name').focus();
    }, 500);
  };

  close = () => {
    this.$modalNode.modal('hide');
    ReactDOM.unmountComponentAtNode(this.modalNode.parentNode);
  };

  isFieldDirty = field => {
    return (this.dirtyFields.includes(field));
  }

  handleFieldChange = e => {
    this.dirtyFields.push(e.target.id);
    const obj = {}
    obj[e.target.id] = e.target.value;
    this.setState(obj);
  }

  toggleConsent = e => {
    this.dirtyFields.push(e.target.id);
    const obj = {}
    obj[e.target.id] = (this.state.manual_consent === 0 ? 1 : 0);
    obj['warning'] = (this.state.manual_consent === 1 ? true : false)
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault();

    $.ajax({
      url: this.props.create_url,
      type: "POST",
      dataType: "json",
      data: { tenant: this.state },
      cache: false,
      success: data => {
        var tenant = data.tenant;
        this.setState(tenant);
        this.close();
      }
    });
  };

  handlePropertyClasses = () => {
    let classString = 'form-group'

    if (this.isFieldDirty('property_id') && !this.isPropertyValid()) {
      classString += ' has-error';
    }

    if (this.state.property_id === '') {
      classString += ' is-placeholder';
    }

    return classString;
  }

  render() {
    const cancelBtn = (
      <a
        className='btn btn-gray spacer-right-xs pull-left'
        data-dismiss='modal'
      >
        {I18n.t('general.cancel')}
      </a>
    );

    const createBtn = (
      <a
        className='btn btn-main-pronotif save-tenant'
        onClick={this.createTenant}
        data-disable-with={
          `${I18n.t('form.save')  } <i class='fa fa-spinner fa-spin'></i>`
        }
        disabled={!this.isTenantValid()}
      >
        {I18n.t('form.save')}
      </a>
    );

    const showWarningBtn = (
      <a
        className='btn btn-main-pronotif save-tenant'
        onClick={this.showWarning}
        data-disable-with={
          `${I18n.t('form.save')  } <i class='fa fa-spinner fa-spin'></i>`
        }
        disabled={!this.isTenantValid()}
      >
        {I18n.t('form.save')}
      </a>
    );

    const invalidEmailMsg = null;
    return (
      <div
        className='modal fade'
        tabIndex='-1'
        ref={ref => {
          (this.modalNode = ref), (this.$modalNode = $(ref));
        }}
      >
        <form className='form'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>
                <h4 className='modal-title text-medium text-md'>
                  {I18n.t('general.new_tenant')}
                </h4>
              </div>
              <div className='modal-body'>
                {invalidEmailMsg}

                <div style={{ display: 'flex' }}>
                  <div className={!this.isFieldDirty('first_name') || this.isFirstNameValid() ? 'form-group' : 'form-group has-error'} style={{ width: '50%', paddingRight: '7.5px' }}>
                    <label htmlFor='first_name'>
                      {I18n.t('form.first_name')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      autoFocus='autofocus'
                      className='form-control'
                      type='text'
                      id='first_name'
                      value={this.state.first_name}
                    />
                    <span hidden={!this.isFieldDirty('first_name') || this.isFirstNameValid()} style={{ color: '#a94442' }}>
                      {I18n.t('form.required_field')}
                    </span>
                  </div>

                  <div className={!this.isFieldDirty('last_name') || this.isLastNameValid() ? 'form-group' : 'form-group has-error'} style={{ width: '50%', paddingLeft: '7.5px' }}>
                    <label htmlFor='last_name'>
                      {I18n.t('form.last_name')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      className='form-control'
                      type='text'
                      id='last_name'
                      value={this.state.last_name}
                    />
                    <span hidden={!this.isFieldDirty('last_name') || this.isLastNameValid()} style={{ color: '#a94442' }}>
                      {I18n.t('form.required_field')}
                    </span>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div className={!this.isFieldDirty('email') || this.isEmailValid() ? 'form-group' : 'form-group has-error'} style={{ width: '50%', paddingRight: '7.5px' }}>
                    <label htmlFor='email'>
                      {I18n.t('form.email')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      className='form-control'
                      type='text'
                      id='email'
                      value={this.state.email}
                    />
                    <div hidden={!this.isFieldDirty('email') || this.isEmailPresent()} style={{ color: '#a94442' }}>
                      {I18n.t('form.required_field')}
                    </div>
                    <div hidden={!this.isFieldDirty('email') || this.isEmailValid()} style={{ color: '#a94442' }}>
                      {I18n.t('form.email_valid')}
                    </div>
                  </div>

                  <div className={!this.isFieldDirty('phone') || this.isPhoneValid() ? 'form-group' : 'form-group has-error'} style={{ width: '50%', paddingLeft: '7.5px' }}>
                    <label htmlFor='phone'>
                      {I18n.t('form.phone')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      className='form-control js-phone-number'
                      type='text'
                      id='phone'
                      value={this.state.phone}
                    />
                    <div hidden={!this.isFieldDirty('phone') || this.isPhoneValid()} style={{ color: '#a94442' }}>
                      {I18n.t('form.phone_valid')}
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  {/* Property */}
                  <div className={this.handlePropertyClasses()} style={{ width: '50%', paddingRight: '7.5px' }}>
                    <label htmlFor='property_id'>
                      {I18n.t('form.property')}
                    </label>
                    <select
                      className='form-control'
                      onChange={this.handleFieldChange}
                      value={this.state.property_id}
                      id='property_id'
                    >
                      <option default key={0} value={''} disabled>
                        {I18n.t('form.default-property')}
                      </option>
                      {this.props.properties.map((property, i) => (
                        <option key={i+1} value={property.id}>
                          {property.name}
                        </option>
                      ))}
                    </select>
                    <span hidden={!this.isFieldDirty('property_id') || this.isPropertyValid()} style={{ color: '#a94442' }}>
                      {I18n.t('form.required_field')}
                    </span>
                    <span hidden={this.props.properties.length > 0} style={{ color: '#FAAD14' }}>
                      {I18n.t('form.has_property')} <a style={{ color: '#FAAD14', textDecorationLine: 'underline' }} href="/properties">{I18n.t('form.has_property2')}</a> {I18n.t('form.has_property3')}
                    </span>
                  </div>

                  {/* Consent */}
                  <div className='checkbox' style={{ width: '50%', paddingLeft: '30.5px', paddingTop: '25px', display: 'flex', alignItems: 'center' }}>
                    <label htmlFor='manual_consent' style={{ display: 'flex', alignItems: 'center' }}>
                      <input type='checkbox' className='form-control ps-0' id='manual_consent' value={this.state.manual_consent} onChange={this.toggleConsent} style={{ width: '13px', marginRight: '5px' }} />
                      <span style={{ marginTop: '5px' }}>{I18n.t('form.willing_accepted')}</span>
                    </label>
                    <a id="not-clickable"
                      data-tooltip-place="top">
                      <i
                        className='fa fa-question-circle text-gray'
                        style={{ marginLeft: '5px', marginTop: '5px' }}
                        onMouseEnter={() => this.setState({ tooltipOpen: true })}
                        onMouseLeave={() => this.setState({ tooltipOpen: false })}
                        onClick={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}
                      />
                    </a>
                    <Tooltip anchorSelect="#not-clickable" className="tooltip-global" isOpen={this.state.tooltipOpen} place="top">
                      <span className='tooltip-title'>{I18n.t('form.tooltip_willing_accepted')}</span>
                    </Tooltip>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  {/* Unit */}
                  <div className='form-group' style={{ width: '50%', paddingRight: '7.5px' }}>
                    <label htmlFor='unit'>
                      {I18n.t('form.unit')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      className='form-control'
                      type='text'
                      id='unit'
                      value={this.state.unit}
                    />
                  </div>

                  {/* Status */}
                  <div className='form-group' style={{ width: '50%', paddingLeft: '7.5px' }}>
                    <label htmlFor='status'>
                      {I18n.t('form.tenant_status')}
                    </label>
                    <select
                      className='form-control'
                      onChange={this.handleFieldChange}
                      value={this.state.status}
                      id='status'
                    >
                      {this.props.tenantStatuses.map((status, i) => (
                        <option key={i} value={status[1]}>
                          {status[0]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  {/* Locale */}
                  <div className='form-group' style={{ width: '50%', paddingRight: '7.5px' }}>
                    <label htmlFor='locale'>
                      {I18n.t('general.language')}
                    </label>
                    <select
                      className='form-control'
                      onChange={this.handleFieldChange}
                      value={this.state.locale}
                      id='locale'
                    >
                      {this.locales.map((locale, i) => (
                        <option key={i} value={locale.code}>
                          {locale.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Org Name */}
                  <div className='form-group' style={{ width: '50%', paddingLeft: '7.5px' }}>
                    <label htmlFor='description'>
                      {I18n.t('form.organization_name')}
                    </label>
                    <input
                      onChange={this.handleFieldChange}
                      className='form-control'
                      type='text'
                      id='description'
                      value={this.state.description}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                {cancelBtn}
                {!this.state.warning ? createBtn : ''}
                {this.state.warning ? showWarningBtn : ''}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default TenantEditor;
