import React      from 'react'
import SmsPreview from './SmsPreview'

export default {
  title:     'SmsCampaign/Step4/MobilePreview',
  component: SmsPreview,
}

const Template = (args) => <SmsPreview {...args} />

export const Primary = Template.bind({})
Primary.args         = {
  subject:   'Text Message',
  timestamp: 'Thu, Nov 19, 1:00 PM',
  message:   `Hello Jane Doe,

Thank you for being a valued Fairstone customer. We have still not received a payment for your loan account.

Please make a payment as soon as possible by contacting us at 1-800-584-4834`,
}