import React, {Component} from 'react'

// Mutate a copy of data without changing the original source
import update from 'immutability-helper'

// Promise based HTTP client for the browser and node.js
import axios from 'axios'

import {DataTable}  from 'primereact/components/datatable/DataTable'
import {Column}     from 'primereact/components/column/Column'
import {Paginator}  from 'primereact/components/paginator/Paginator'
import SearchHeader from './SearchHeader'

class SmsTable extends Component {

  static defaultProps = {
    loaderColor: '#0056ba',
  }

  constructor(props) {
    super(props)

    const initialState = {
      loading:     true,
      data:        [],
      queryParams: {
        'page[number]': 1,
        'page[size]':   5,
        sort:           '-sentAt',
        q:              '',
      },
    }
    // const [state, setState] = useState(initialState)
    this.state = initialState
  }

  componentDidMount() {
    this.loadData(this.props.dataSource, this.state.queryParams).then(() => {
      this.setState({
        rows: this.state.data.length,
      })
    })
  }

  onFilterChange = (event) => {
    if (this.state.filterTimerId) {
      clearTimeout(this.state.filterTimerId)
    }

    let filterValue = event.target.value

    let filterTimerId = setTimeout(() => {
      let newState = update(this.state, {queryParams: {q: {$set: filterValue}}})
      this.setState(newState)

      this.loadData(this.props.dataSource, newState.queryParams)
    }, 250)

    this.setState({filterTimerId: filterTimerId})
  }

  onPageChange = ({first, page, rows}) => {
    let currentPage = page + 1 // index of the page array (zero based)

    let newState = update(this.state, {queryParams: {'page[number]': {$set: currentPage}}})
    this.setState(newState)

    this.loadData(this.props.dataSource, newState.queryParams).then(() => {
      this.setState({
        first,
        rows,
      })
    })
  }

  onSort = (event) => {
    let sortParam = (event.sortOrder == -1) ? [
      '-',
      event.sortField,
    ].join('') : event.sortField

    let newState = update(this.state, {queryParams: {sort: {$set: sortParam}}})
    this.setState(newState)

    this.loadData(this.props.dataSource, newState.queryParams).then(() => {
      this.setState({
        sortField: event.sortField,
        sortOrder: event.sortOrder,
      })
    })
  }

  loadData = (dataSource, query = {}) => {
    this.setState({
      loading: true,
    })

    let promise =
          axios.get(dataSource, {params: query})
            .then((response) => {
              const data = response.data.data || []
              const meta = response.data.meta || {}

              this.setState({
                totalRecords: meta.pagination.totalCount,
                totalPages:   meta.pagination.totalPages,
                pageSize:     Number(meta.pagination.perPage),
                data:         data,
                loading:      false,
              })
            })
            .catch((error) => {
              this.setState({loading: false})
            })

    return promise
  }

  render() {
    return (
      <>
        <SearchHeader onChange={this.onFilterChange} />

        <DataTable
          value={this.state.data}
          rows={this.state.rows}
          loading={this.state.loading}
          responsive={true}
          emptyMessage="No records found"

          // Sorting
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
          onSort={this.onSort}
          tableClassName="table"
        >

          <Column
            field="fullName"
            header={I18n.t('general.from')}
            sortable={true}
            filterMatchMode="contains"
          />
          <Column
            field="accountNumber"
            header={I18n.t('form.client_number')}
            sortable={true}
            filterMatchMode="contains"
          />
          <Column
            field="sentAt"
            sortField="sentAt"
            header={I18n.t('general.date')}
            sortable={true}
            style={{whiteSpace: 'nowrap'}}
          />
          <Column
            field="deliveryStatus"
            header="Status"
            sortable={true}
            filterMatchMode="contains"
          />

        </DataTable>

        {this.state.totalPages && this.state.totalPages > 1 &&
        <Paginator
          first={this.state.first}
          rows={this.state.pageSize}
          totalRecords={this.state.totalRecords}
          onPageChange={this.onPageChange}
          style={{paddingTop: '20px !important'}}
        />
        }

      </>
    )
  }
}

export default SmsTable