import React                       from 'react'
import SmsPreview                  from '../SmsPreview'
import {Total, Summary, Container} from './SmsPreviewPage.styles'

const SmsPreviewPage = props =>
  <Container>
    <Summary>
      Your SMS will be sent to <Total>{props.totalRecipients} recipients</Total> and will look like
      this
    </Summary>
    <SmsPreview {...props} />
  </Container>

export default SmsPreviewPage

