import React from 'react';
import { Tooltip } from 'react-tooltip';

class TooltipGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }

  render() {
    // Ensure the title string is properly formatted with <br> tags for new lines
    const titleString = this.props.title.replace(/\n/g, '<br>');
    const iconString = this.props.icon || 'fa-question-circle';
    return (
      <>
        <a id={`tooltip-anchor-${this.props.idx}`} data-tooltip-place='top'>
          <i
            className={`fa ${iconString} text-gray`}
            style={{ marginLeft: '5px', marginTop: '5px' }}
            onMouseEnter={() => this.setState({ tooltipOpen: true })}
            onMouseLeave={() => this.setState({ tooltipOpen: false })}
            onClick={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}
          />
        </a>
        <Tooltip anchorSelect={`#tooltip-anchor-${this.props.idx}`} className='tooltip-global' isOpen={this.state.tooltipOpen} place='top'>
          <span className='tooltip-title' style={{ textTransform: 'none', textWrap: 'balance', wordBreak: 'normal' }} dangerouslySetInnerHTML={{ __html: titleString }} />
        </Tooltip>
      </>
    );
  }
}

export default TooltipGenerator;
