import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ActivityTabs from './ActivityTabs';
export class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabKey: 1,
      expanded: false,
      showAttachmentsModal: false
    };
  }

  onHeaderClick = (e) => {
    this.setState(prevState => ({    // prevState?
      expanded: !prevState.expanded
    }));
  }

  onRepliesClick = (event) => {
    this.setState({
      expanded: true,
      activeTabKey: 1
    })
  }

  onAttachmentsClick = () => {
    this.setState({ showAttachmentsModal: true });
  }

  handleHideAttachmentsModal = () => {
    this.setState({ showAttachmentsModal: false });
  }

  arrowClass = () => {
    if (this.state.expanded == false) {
      return 'fa-chevron-down';
    } else {
      return 'fa-chevron-up';
    }
  }

  renderItemBody = () => {
    const activity = this.props.activity;

    let amountCollectedLink = "";

    let repliesLink = "";

    if (activity.recipients[0].replies_count > 0) {
      repliesLink = <a onClick={this.onRepliesClick} className="text-blue font-size-16 text-regular link-underline block">{activity.recipients[0].replies_count}</a>
    } else {
      repliesLink = <span className="text-gray font-size-15 text-regular block">{(activity.enable_replies == true || activity.type == "CollectionRequest") ? activity.recipients[0].replies_count : I18n.t("general.disabled")}</span>
    }

    let AttachmentsLink = "";

    if (activity.recipients[0].documents.length > 0) {
      AttachmentsLink = <a onClick={this.onAttachmentsClick} className="navy-link font-size-16 text-regular block">{activity.recipients[0].documents.length}</a>
    } else {
      AttachmentsLink = <span className="text-gray font-size-16 text-regular block">{activity.recipients[0].documents.length}</span>
    }

    return (
      <div key={`activity_body_${activity.id}`} className="row spacer-top-xxs">
        <div className="col-xs-8">
          <span className="text-dark-gray font-size-14 text-light block">{I18n.t("people.attachments")}</span>
          {AttachmentsLink}
        </div>
        <div className="col-xs-4 text-right">
          <span className="text-dark-gray font-size-14 text-light block">{I18n.t("people.replies")}</span>
          {repliesLink}
        </div>
      </div>
    )
  }

  renderTabs = () => {
    const activity = this.props.activity;

    return (
      <ActivityTabs key={activity.id} activity={activity} expanded={this.state.expanded} activeTabKey={this.state.activeTabKey} />
    )
  }

  render() {
    let activity = this.props.activity;

    let recipientStatus = "";
    if (activity.recipients[0].latest_status == "failed") {
      recipientStatus = <a href={"/activities/" + activity.id + "/recipients/" + activity.recipients[0].id + "/edit"} data-remote-modal="true" className="text-red font-size-14 text-regular link-underline spacer-right-xs"><i className={"m-r-5 " + activity.recipients[0].status_icon_class}></i>{I18n.t(`people.recipient_latest_status.${activity.recipients[0].latest_status}`)}</a>
    } else {
      recipientStatus = <span className={"font-size-14 text-light spacer-right-xs " + activity.recipients[0].status_color}><i className={"m-r-5 " + activity.recipients[0].status_icon_class}></i>{I18n.t(`people.recipient_latest_status.${activity.recipients[0].latest_status}`)}</span>
    }

    let internalReference = activity.internal_file_number != null ? <span className="text-dark-gray font-size-14 text-light spacer-right-xs"><i className="fal fa-clipboard-list m-r-5"></i>{activity.internal_file_number}</span> : "";

    // Links
    let resendRecipientLink = activity.recipients[0].resend_allowed ? <a href={activity.recipients[0].resend_url} data-method="post" data-confirm={I18n.t("activities.resend.nocharge_recipient_confirm", { recipient_name: activity.person.full_name })} className="navy-link spacer-left-xs"><i className="fal fa-redo-alt m-r-5"></i>{I18n.t("general.resend")}</a> : "";
    let activityDetailsLink = <a href={activity.outbox_url} className="navy-link spacer-left-xs"><i className="fal fa-info-square m-r-5"></i>{I18n.t("general.view_in_outbox")}</a>;

    return (
      <div className="p-col-12 dataview-list-item m-b-10">
        <div className="p-grid">
          <div className="row" id="person-activity-list-item-toggle" onClick={this.onHeaderClick}>
            <div className="col-xs-10">
              <span className="text-dark-gray font-size-18 text-regular">{activity.subject}</span>
              <p>
                <span className="text-dark-gray font-size-14 text-light spacer-right-xs"><i className="fal fa-clock m-r-5"></i>{activity.emails_sent_at_formatted}</span>
                <span className="text-dark-gray font-size-14 text-light spacer-right-xs"><i className={"m-r-5 " + activity.type_icon_class}></i>{I18n.t(`activities.email.type_name_for_header.${activity.type.toLowerCase()}`)}</span>
                {internalReference}
                {recipientStatus}
              </p>
            </div>
            <div className="col-xs-2 text-right">
              <i className={'far font-size-20 text-navy ' + this.arrowClass()}></i>
            </div>
          </div>

          {this.renderItemBody()}

          <div className="spacer-top-xxs text-right">
            <span className="">
              <a href={activity.recipients[0].proof_report_url} target="_blank" className="navy-link spacer-left-xs"><i className="fal fa-receipt m-r-5"></i>{I18n.t("general.view_full_report")}</a>
              {resendRecipientLink}
              {this.props.showDetails ? activityDetailsLink : ''}
            </span>
          </div>

          {this.renderTabs()}
        </div>

        <Modal
          show={this.state.showAttachmentsModal}
          onHide={this.handleHideAttachmentsModal}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title text-medium text-md">{I18n.t("people.attachments")}</h4>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-xs-12 spacer-bottom-xs">
                <div className="table-sticky-header-container">
                  <table className="table table-hover table-sticky-header text-regular table-light-td-bt">
                    <thead>
                      <tr>
                        <th className="">{I18n.t("general.file_name")}</th>
                        <th className="text-right">{I18n.t("general.size")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (activity.recipients[0].documents != null && activity.recipients[0].documents.length > 0) ?
                          activity.recipients[0].documents.map((doc, i) =>
                            <tr key={doc.id} className="js-clickable-row" data-href={doc.file_url} target="_blank">
                              <td className="text-dark-gray text-regular font-size-14">{doc.file_name}</td>
                              <td className="text-dark-gray text-regular font-size-14 text-right">{doc.file_size_formatted}</td>
                            </tr>
                          )
                          :
                          (
                            <tr>
                              <td className="text-center" colSpan="2">{I18n.t("blank_state.general.title")}</td>
                            </tr>
                          )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.handleHideAttachmentsModal} className="btn btn-gray">{I18n.t("general.close")}</button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

export default Activity;
