// Basic hybrid auth example following the pattern at:
// https://developers.google.com/identity/sign-in/web/server-side-flow

$(document).on("turbolinks:load", function () {  
  $(document).on('click', '.btn-google', function(e) {  
    var origin = $(this).attr("data-origin");
    var callbackUrl = $(this).attr("data-callback-url");
    var loadingState =  $(this).attr("data-loader");
    var container = $(this);

    var beforeCallback = function () {
      container.find('.text-div').html(loadingState);
    };
    
    window.auth2.grantOfflineAccess().then(
      function (authResult) {
        signInCallback(authResult, beforeCallback, {origin: origin, callbackUrl: callbackUrl}); 
      }
    );

  });

  var signInCallback = function(authResult, beforeCallback, options) {    
    if (authResult['code']) {
      // Send the code to the server
      $.ajax({
        type: 'POST',
        // Always include an `X-Requested-With` header in every AJAX request,
        url: [options["callbackUrl"], $.param({code: authResult['code'], origin: options["origin"]})].join('&'),
        // Always include an `X-Requested-With` header in every AJAX request,
        // to protect against CSRF attacks.
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        contentType: 'application/octet-stream; charset=utf-8',
        beforeSend: beforeCallback,
        processData: false
      });
    };
  };
})  