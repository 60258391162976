$(function() {
  var testimonials = $("#testimony-carousel .testimony-card");
  var testimonialsLoopInterval;

  function getRandomTestimony() {
    var hiddenTestimonials = $("#testimony-carousel .testimony-card:not(.active)");
    return hiddenTestimonials[Math.floor(Math.random() * hiddenTestimonials.length)];
  }

  // Make sure we actually have some testimonies
  function loopTestimonials() {
    var currentActive = $("#testimony-carousel .testimony-card.active");

    var hiddenTestimony = getRandomTestimony();

    $(currentActive).fadeOut('slow').removeClass('active').addClass('hide');
    $(hiddenTestimony).fadeIn('slow').removeClass('hide').addClass('active');
  }

  if (testimonials.length > 0) {
    testimonialsLoopInterval = setInterval(function() { loopTestimonials() }, 10000);
  }
});
