import React, { useState, useEffect } from 'react';
import Calendar from './Calendar';
import camelizeProps from "react-camelize-props";
import PropTypes from 'prop-types';

const DateTimePicker = (props) => {
  let date = moment().toDate()
  if (props.reportType === 'filters') {
    if (props.value) {
      const d = new Date(props.value)
      date = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    } else {
      date = props.placeholder
    }
  }

  const mxDate = props.maxDate ? moment(props.maxDate).toDate() : moment().toDate();
  const [maxDate, setMaxDate] = useState(mxDate)

  const minDateDuration = props.disableDefaultMinDate ? 12000 : 12;
  const mnDate = props.minDate ? moment(props.minDate).toDate() : moment().subtract(minDateDuration, 'months').toDate();
  const [minDate, setMinDate] = useState(mnDate)

  const [currentDate, setCurrentDate] = useState(date);

  var dateFormat = I18n.locale === "fr" ? "d MM yy" : "MM d, yy";

  var localeObj = {
    firstDayOfWeek: 1,
    dayNames: [I18n.t("calendar.weekdays.sun"), I18n.t("calendar.weekdays.mon"), I18n.t("calendar.weekdays.tue"), I18n.t("calendar.weekdays.wed"), I18n.t("calendar.weekdays.thu"), I18n.t("calendar.weekdays.fri"), I18n.t("calendar.weekdays.sat")],
    dayNamesShort: [I18n.t("calendar.weekdays_short.sun"), I18n.t("calendar.weekdays_short.mon"), I18n.t("calendar.weekdays_short.tue"), I18n.t("calendar.weekdays_short.wed"), I18n.t("calendar.weekdays_short.thu"), I18n.t("calendar.weekdays_short.fri"), I18n.t("calendar.weekdays_short.sat")],
    dayNamesMin: [I18n.t("calendar.weekdays_min.sun"), I18n.t("calendar.weekdays_min.mon"), I18n.t("calendar.weekdays_min.tue"), I18n.t("calendar.weekdays_min.wed"), I18n.t("calendar.weekdays_min.thu"), I18n.t("calendar.weekdays_min.fri"), I18n.t("calendar.weekdays_min.sat")],
    monthNames: [I18n.t("calendar.months.jan"), I18n.t("calendar.months.feb"), I18n.t("calendar.months.mar"), I18n.t("calendar.months.apr"), I18n.t("calendar.months.may"), I18n.t("calendar.months.jun"), I18n.t("calendar.months.jul"), I18n.t("calendar.months.aug"), I18n.t("calendar.months.sep"), I18n.t("calendar.months.oct"), I18n.t("calendar.months.nov"), I18n.t("calendar.months.dec")],
    monthNamesShort: [I18n.t("calendar.months_short.jan"), I18n.t("calendar.months_short.feb"), I18n.t("calendar.months_short.mar"), I18n.t("calendar.months_short.apr"), I18n.t("calendar.months_short.may"), I18n.t("calendar.months_short.jun"), I18n.t("calendar.months_short.jul"), I18n.t("calendar.months_short.aug"), I18n.t("calendar.months_short.sep"), I18n.t("calendar.months_short.oct"), I18n.t("calendar.months_short.nov"), I18n.t("calendar.months_short.dec")],
  };

  const handleChange = (event) => {
    // set new date
    if (props.id === 'future_send_date_picker' || props.id === 'close_date_picker') {
      $(`#${props.id}`).data("DateTimePicker").date(event.value);
    } else if (props.id === 'datetimepicker6' || props.id === 'datetimepicker7') {
      if (props.reportType === 'proof') {
        $("#account-settings-compiled-report-section").find(`.${props.id}`).data("DateTimePicker").date(event.value)
      } else if (props.reportType === 'recipient') {
        $("#account-settings-compiled-recipient-report-section").find(`.${props.id}`).data("DateTimePicker").date(event.value)
      } else if (props.reportType === 'billing') {
        $("#account-settings-compiled-billing-report-section").find(`.${props.id}`).data("DateTimePicker").date(event.value)
      } else {
        $("#account-settings-compiled-activity-report-section").find(`.${props.id}`).data("DateTimePicker").date(event.value)
      }
    } else if (props.id === 'datetimepickerFiltersFrom' || props.id === 'datetimepickerFiltersTo') {
      if (props.reportType === 'filters') {
        const date = new Date(event.value).toISOString().split("T")[0];
        console.log(`Using ${props.id} - CHANGED DATE TO: ${  date}`)
        $(".notifications-filter").find(`.${props.id}`).val(date)
        $('.notifications-filter .sent-form').submit();
      }
    }

    setCurrentDate(event.value)
  }

  useEffect(() => {
    //when mounting get date & pass it to Calendar
    if (props.id === 'future_send_date_picker' || props.id === 'close_date_picker') {
      setCurrentDate(moment().toDate())
      let isDate = $('#' + props.id).data("DateTimePicker").date()
      if (isDate) {
        setCurrentDate($('#' + props.id).data("DateTimePicker").date()._d)
      }

      setMinDate(moment().toDate())
      setMaxDate(moment().add(6, 'months').toDate())
    }

    if (props.id === 'datetimepicker7' && props.param === 'end_date') {
      setCurrentDate(currentDate)

      if (props.reportType === 'proof') {
        $("#account-settings-compiled-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      } else if (props.reportType === 'recipient') {
        $("#account-settings-compiled-recipient-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      } else if (props.reportType === 'billing') {
        $("#account-settings-compiled-billing-report-section").find('.' +  props.id).data("DateTimePicker").date(currentDate)
      } else {
        $("#account-settings-compiled-activity-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      }

    } else if (props.id === 'datetimepicker6' && props.param === 'start_date') {
      if (props.reportType === 'proof') {
        $("#account-settings-compiled-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      } else if (props.reportType === 'recipient') {
        $("#account-settings-compiled-recipient-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      } else if (props.reportType === 'billing') {
        $("#account-settings-compiled-billing-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      } else {
        $("#account-settings-compiled-activity-report-section").find('.' + props.id).data("DateTimePicker").date(currentDate)
      }

    }

  }, []);

  let settingsDTP = (<Calendar
    showTime={props.showTime}
    placeholder={props.placeholder}
    hourFormat="12"
    showIcon={true}
    selectOtherMonths={true}
    locale={localeObj}
    dateFormat={dateFormat}
    value={currentDate}
    onChange={handleChange}
    minDate={minDate}
    maxDate={maxDate}
    readonlyInput={props.readOnly}
    inputId={props.id + '_' + props.reportType}
    inputClassName="form-control">
  </Calendar>)

  let collectionRequestDTP = (<Calendar
    showTime={props.showTime}
    placeholder={props.placeholder}
    hourFormat="12"
    showIcon={true}
    selectOtherMonths={true}
    locale={localeObj}
    dateFormat={dateFormat}
    value={currentDate}
    onChange={handleChange}
    minDate={minDate}
    maxDate={maxDate}
    readonlyInput={props.readOnly}
    inputId={props.id + '_react'}
    inputClassName="form-control">
  </Calendar>)

  if (props.id === 'datetimepicker6' || props.id === 'datetimepicker7') {
    return settingsDTP
  } else {
    return collectionRequestDTP
  }
}

DateTimePicker.propTypes = {
  param: PropTypes.string.isRequired
};

export default camelizeProps(DateTimePicker);
