import React from "react"
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import PropertyList from './PropertyList';
import TenantList from './TenantList';

class PropertyPicker extends React.Component {
  static propTypes = {
    propertiesData: PropTypes.string.isRequired,
    tenantsData: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showProperties: true,
      showTenants: false,
      selectedProperty: {},
      selectedTenants: [],
      properties: [],
      inputValue: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show != this.props.show) {
      this.setState({
        show: this.props.show
      })
    }
  }

  open = () => {
    this.setState({
      show: true,
      showProperties: true,
      showTenants: false,
      selectedProperty: {},
      selectedTenants: [],
      inputValue: ""
    })
  }

  close = () => {
    this.setState({
      show: false
    })
  }

  selectProperty = (property, event) => {
    event.preventDefault();

    this.setState({
      showProperties: false,
      showTenants: true,
      selectedProperty: property
    })

  }

  hideTenants = (event) => {
    event.preventDefault();

    this.setState({
      showProperties: true,
      showTenants: false,
      selectedProperty: {}
    })
  }

  showTenants = (property, event) => {
    event.preventDefault();

    this.setState({
      showProperties: false,
      showTenants: true,
      selectedProperty: property
    })
  }

  showAllTenants = (event) => {
    event.preventDefault();

    this.setState({
      showProperties: false,
      showTenants: true,
      selectedProperty: null
    })
  }

  showQuery = (value, event) => {
    event.preventDefault()

    this.setState({
      showProperties: false,
      showTenants: true,
      selectedProperty: null,
      inputValue: value
    })
  }

  updateSelectedTenants = (tenants) => {
    this.setState({
      selectedTenants: tenants
    })
  }



  render() {
    var property = this.state.selectedProperty || {}
    var propertyName = property.name
    var tenantsTitle = propertyName ? [[I18n.t("general.tenants")], propertyName].join(' - ') : I18n.t("general.tenants")

    return (
      <Modal show={this.state.show} onHide={this.close} dialogClassName="modal-lg">

        <Modal.Header closeButton={true}>
          <Modal.Title className="modal-title text-medium text-md">
            {this.state.showTenants ? tenantsTitle : I18n.t("general.properties")}
          </Modal.Title>
        </Modal.Header>

        <div className="modal-body">

          <PropertyList
            dataSourceUrl={this.props.propertiesData}
            showAllTenants={this.showAllTenants}
            search={this.showQuery}
            onSelect={this.selectProperty}
            show={this.state.showProperties}
          />

          <TenantList
            dataSourceUrl={this.props.tenantsData}
            property={this.state.selectedProperty}
            show={this.state.showTenants}
            search={this.showQuery}
            onHide={this.hideTenants}
            onClose={this.close}
            value={this.state.inputValue}
          />
        </div>

        <div className={classNames("modal-footer", { "hidden": !this.state.showProperties })}>
          <div className="row">
            <div className="col-xs-12 text-right">
              <button type="button" className="btn btn-gray btn-custom-margin" data-dismiss="modal" onClick={this.close}>{I18n.t("general.cancel")}</button>
            </div>
          </div>
        </div>

      </Modal>
    );
  }
};

export default PropertyPicker;
