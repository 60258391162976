import React                              from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Container}          from './SmsConversation.styles'

const queryClient = new QueryClient()

const ConversationData = ({debtorId}) => {
  // Access the client

  return <>{
  }</>
}

export const SmsConversation = props =>
  <Container>
    <QueryClientProvider client={queryClient}>
      <ConversationData {...props} />
    </QueryClientProvider>
  </Container>

export default SmsConversation

